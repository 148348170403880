.indraft-card-container {
	padding: 0% !important;
}
.indraft-card-item {
	padding: 15px !important;
}

.grid-card {
	padding: 10px 20px;
	height: 100%;
}
.grid-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.grid-inner-head {
	display: flex;
	align-items: baseline;
}
.sales-container .grid-icon-container,
.sales-container .grid-user-name {
	flex: 0 0 auto;
}
.right-content-inner .grid-icon-container img {
	margin-right: 21px;
}
.grid-user-name {
	margin-left: 10px;
	position: relative;
	top: -4px;
}
.grid-banner {
	margin: 10px -20px;
}
.news-time {
	font-size: 12px !important;
	color: #9c9bb2;
}
.right-content-wrapper {
	position: fixed;
	width: 28%;
	right: 0;
	top: 68px;
	padding: 10px;
	height: calc(100% - 68px);
	box-shadow: -1px -3px 5px #b6b6b8;
	overflow-y: scroll;
	overflow-x: hidden;
}
.grid-card.right-grid-card {
	padding: 18px 20px;
	margin-bottom: 10px;
	background: #232f44;
	cursor: pointer;
}
.grid-card.right-grid-card.notification-grid-card {
	position: relative;
	background: #233044; /* Old browsers */
	background: -moz-linear-gradient(left, #233044 32%, #2b4b48 46%, #2f564a 54%, #39774f 73%); /* FF3.6-15 */
	background: -webkit-linear-gradient(
		left,
		#233044 32%,
		#2b4b48 46%,
		#2f564a 54%,
		#39774f 73%
	); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(
		to right,
		#233044 32%,
		#2b4b48 46%,
		#2f564a 54%,
		#39774f 73%
	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(
			startColorstr='#233044',
			endColorstr='#39774f',
			GradientType=1
		); /* IE6-9 */
}
.grid-card.right-grid-card.notification-grid-card:before {
	content: '';
	position: absolute;
	right: 0;
	top: 0;
	width: 110px;
	height: 96px;
	background: url(../../../../assets/images/content-images/notification-icon.png) 0 0 no-repeat;
}
.right-grid-card .news-time {
	font-size: 12px;
	color: #a7abb4;
	margin-top: 15px;
	margin-left: 47px !important;
}
.right-grid-card .grid-user-name {
	color: #fff;
	padding-right: 10px;
}
.name-content,
.company-name {
	font-weight: bold;
}
.sales-main-wrapper {
	padding: 0 15px !important;
}

@media (max-width: 1024px) {
	.indraft-card-item {
		flex-grow: 0;
		max-width: 100% !important;
		flex-basis: 100% !important;
	}
}

@media (max-width: 767px) {
	.sales-container {
		margin-bottom: 20px;
	}
	.right-content-wrapper {
		position: inherit;
		width: 100%;
		height: auto;
		order: 2;
		top: 0;
		overflow-y: hidden;
		box-shadow: none;
		padding: 0;
	}
	.sales-main-wrapper {
		display: flex;
		flex-direction: column;
	}

	.sales-main-content {
		order: 3;
	}
	.row.right-container {
		flex-wrap: nowrap;
		overflow-x: auto;
		overflow-y: hidden;
	}
	.notification-inner-grid {
		height: 100%;
		padding-bottom: 10px;
	}
	.mobile-logo-content {
		display: flex;
	}
}

.sales-news {
	cursor: pointer;
	height: 100%;
}

.sales-news:hover {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.breadcrumbs-container {
	color: #636d7e;
}

.profile-image {
	width: 36px;
	height: 36px;
	border-radius: 3px;
}

.sales-news-image {
	height: 150px;
}

.grid-text {
	margin: 14px 0 7px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
.sales-container {
	margin-bottom: 30px;
}
.action-btn {
	margin: 2% !important;
	padding: 1.5% 5% !important;
	text-transform: none !important;
}
