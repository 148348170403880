.cust_dropdown{
    position: relative;
    box-sizing: border-box;
    outline: none;
  }
  .cust_dropdown_heading_open{
    box-sizing: border-box;
      background-color: rgb(255, 255, 255);
      border-color: rgb(217, 217, 217) rgb(204, 204, 204) rgb(179, 179, 179);
      border-radius: 4px 4px 0px 0px;
      border-width: 1px;
      border-style: solid;
      border-image: initial;
      color: rgb(51, 51, 51);
      cursor: pointer;
      display: table;
      border-spacing: 0px;
      border-collapse: separate;
      height: 36px;
      outline: none;
      overflow: hidden;
      position: relative;
      width: 100%;
  }
  
  .cust_dropdown_heading{
    box-sizing: border-box;
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(204, 204, 204);
      border-radius: 4px;
      color: rgb(51, 51, 51);
      cursor: default;
      display: table;
      border-spacing: 0px;
      border-collapse: separate;
      height: 36px;
      outline: none;
      overflow: hidden;
      position: relative;
      width: 100%;
  }
  .cust_dropdown_heading_value{
    box-sizing: border-box;
    bottom: 0px;
    color: rgb(51, 51, 51);
    left: 0px;
    line-height: 34px;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    right: 0px;
    top: 0px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .cust_dropdown_heading_dropdown_arrow{
    box-sizing: border-box;
    cursor: pointer;
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 25px;
    padding-right: 5px;
  }
  .cust_dropdown_content{
    border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      background-color: rgb(255, 255, 255);
      border-width: 1px;
      border-style: solid;
      border-color: rgb(230, 230, 230) rgb(204, 204, 204) rgb(204, 204, 204);
      border-image: initial;
      box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 0px;
      box-sizing: border-box;
      margin-top: -1px;
      max-height: 300px;
      position: absolute;
      top: 100%;
      width: 100%;
      z-index: 1;
      overflow-y: auto;
  }
  .cust_select_panel{
    box-sizing: border-box;
  }
  .cust_search_box{
    width: 100%;
    box-sizing: border-box;
    padding: 0.5em;
  }
  .cust_search_input{
    display: block;
    max-width: 100%;
    border-radius: 3px;
    box-sizing: border-box;
    height: 30px;
    line-height: 24px;
    border: 1px solid rgb(222, 226, 228);
    padding: 10px;
    width: 100%;
    outline: none;
  }
  .cust_select_list{
    margin: 0px;
    padding-left: 0px;
  }
  .cust_select_li{
    list-style: none;
    
    :hover{
      background-color: rgb(235, 245, 255);
    }
  }
  .cust_select_item{
    background-color: rgb(235, 245, 255);
  }
  .cust_item{
    box-sizing: border-box; 
    display: block; 
    cursor: pointer;
    padding: 5px 0 5px 12px;
    margin: 3px 0 3px 0px;
    margin-bottom: 0px !important;
    // :hover{
    //   box-sizing: border-box; background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); cursor: pointer; display: block; padding: 8px 10px;
    // }
    // :focus{
    //   box-sizing: border-box; background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); cursor: pointer; display: block; padding: 8px 10px;
    // }
  }
  .cust_up_arrow{
    box-sizing: border-box; border-color: transparent transparent rgb(153, 153, 153); border-style: solid; border-width: 0px 5px 5px; display: inline-block; height: 0px; width: 0px; position: relative; top: -2px;
  }
  .cust_dwn_arrow{
    box-sizing: border-box; border-color: rgb(153, 153, 153) transparent transparent; border-style: solid; border-width: 5px 5px 2.5px; display: inline-block; height: 0px; width: 0px; position: relative;
  }
.indraft-card-item {
    padding: 15px !important;
    background-color: blue($color: #000000);
}

.titleReward {
    margin: 0px;
    font-size: 20px;
    font-weight: bold;
    color: #232f44;
    text-align: left;
  }

.dateRangePicker {
    padding-left: 80px !important;
}

.activities_container {
    width: 100%;
    border-radius: 5px;
    background-color: #ffffff;
    color: #1c2535;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    hr {
        margin: 0;
    }
}

.activities_container_header {
    width: 100%;
    height: 54px;
    background-color: #232f44;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.activities_container_heading {
    font-size: 16px;
    color: #ffffff;
    margin: 0;
}

.MuiInput-underline-222 {
    border-bottom: none !important;
    // color: #fff;
}

.chart_container {
    // background-color: red;

    .MuiInputBase-root-231 {
        border-bottom: none !important;
        color: #fff !important;
    }
}

// .MuiInputBase-root-231 {
//     // border-bottom: none !important;
//     color: #fff !important;
// }

.tile_headings {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 0.875rem !important;
}

.grid_title_text {
    font-size: 16px;
    color: #ffffff;
}


.card .col{
    // min-width: 275,
    align-self: center !important;
}


.employee_card {
    .count {
        font-size: 32px;
        font-weight: 600;
    }

    .values {
        font-size: 18px;
        font-weight: bold;
    }
    .titalValue {
        font-size: 18px;
        font-weight: bold;
    }

    .label {
        font-size: 18px;

    }

    .empolyee_image {
        height: 100px;
        width: 100px;
        border-radius: 50px;
    }

    .load_more {
        display: block;
        border: 0;
        margin: 1em auto;
        padding: .75em 1em;
        font-size: 1em;
        background-color: #c34d41;
        color: #fff;
        font-weight: 500;
        text-transform: uppercase;
        cursor: pointer
    }

}

.title_Pulselite {
    margin: 0px;
    font-size: 0.875rem !important;
    font-weight: bold;
    color: #232f44;
    text-align: left;
}

.image_cropper {
    display: inline-flex;
    width: 115px;
    height: 115px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    background-color: white;
}

.icon_pic {
    max-width: 100%;
    height: auto;
    width: 68%;
}

.icon_excel {
    cursor: pointer;
    width: 31px;
}

.icon_pdf {
    cursor: pointer;
    width: 25px;
}

.content_values1 {
    color: #17d98b;
    font-weight: 500;
    font-size: 36px !important;
}

.first_content_text {
    font-size: 18px !important;
    margin-top: -8px;
    color: #232f44 !important;
    font-weight: 700;
}

// .first_content_style {
//     margin-top: 10%;
// }

.display_name {
    font: Bold 20px/24px Open Sans;
    letter-spacing: 0;
    color: #232F44;
    opacity: 1;
    margin-bottom: 1%;
    padding-left: 20px;
}



.FullMargin {
    margin-right: 6rem !important;
}

.details {
    align-self: center;
    padding-left: 40px;
}

.rightSideSpace {
    margin-right: 15px;
    height: 78px;
}

// Month Picker
.inputStyle {
    height: 40px;
    border: solid 1px #c0c0c0;
    margin-bottom: 0px;
    /* margin-top: 5px !important; */
    width: 100%;
    padding: 5px;
    border-radius: 3px;
  }

  .datepicker_select {
    background: none;
  }

  .datepicker:first-child {
    width: 100%;
  }
  .datepicker {
    position: relative;
  }

  .headerRightSection {
    display: flex;
    padding-left: 20px;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .date_picker_icon {
    background: url(../../../assets/images/content-images/calender.png) 0 0
      no-repeat;
    width: 22px;
    height: 21px;
    display: inline-block;
    position: absolute;
    right: 125px;
    top: 10px;
    //cursor: pointer !important;
    pointer-events: none;
  }

  .form_group {
      padding-right: 15px;
  }

  .topSection {
    display: flex;
    justify-content: center;
    /* align-self: center; */
    /* align-items: center; */
    flex-direction: column;
  }
  
  .topSectionLeft {
    display: flex;
    justify-content: center;
    /* align-self: center; */
    align-items: center;
    flex-direction: column;
  }

  .rowChild {
      float: right;
      padding-right: 20px;
  }

  .col_xs_6 {
    width: 50% !important;
  }

  .col_xs_8 {
    width: 70% !important;
  }

  .col_xs_4 {
    width: 30% !important;
  }

  .pdfDiv {
    margin-left: 1rem !important;
   }

.selectOrgOptions {
    background-color: hsl(0,0%,100%);
    border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    max-width: 250px !important;
    padding: 0px !important;
    margin: 0 !important; 
    height: 2.5em;
}
.pulseLiteContainer_title_Pulselite__3RnAh{
    font-size: 10px !important;
}

   @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 568px)
    and (-webkit-min-device-pixel-ratio: 2) {
      .cust_dropdown {
         width: 50% !important; 
       }
       .rewardsDatePicker {
          padding-left: 24%;
          margin-left: -20%;
          margin-top: 4%;
       }
       .col_xs_4 {
          width: 20% !important;
          margin-top: 4%;
          padding-left: 1% ;
       }
    }


  @media(max-width:768px){
    .excelpdf {
        text-align: center;
    }

    .rowChild {
        float: none;
    }

    .container-body {
        margin-top: 0px;
    }
    
    .pdfDiv {
        margin-left: 0rem !important;
    }
    // .content-values{ font-size: 36px !important; }    
    .icon_pic { width: 138px !important;}
    .content_values1 {
      text-align: center !important;
      font-size: 18px !important;
    }
    .title_Pulselite {
      text-align: center !important;
      font-size: 18px !important;
    }
    .empoyeeMargin {
        margin: 10px;
    }
    .selectDropdownTitle { 
        display: block !important;
        padding-right: 0px !important;
        border: 1px solid rgb(204, 204, 204);
        margin-bottom: 5px;
    }
    // .sales-content-text {font-size: 18px !important; margin-top: 0px;}
    // .item-title{ font-size: 14px !important;}
    }
    @media(max-width:753px){
    // .card-title.community-header{ display: block; text-align: center; height: 100%; margin: 16px 0 16px 0; border-bottom: 0px;}
    // .card-title.community-header .title{ display: block; width: 100%; margin: 0px;}
    }
    @media(max-width:767px){
        // .item-title{
        //     width: 170px;
        //     white-space: nowrap;
        //     text-overflow: ellipsis;
        //     overflow: hidden;
        // }
    }