.add_tasks {
    width: 751px;
    background-color: #ffffff;
    border-radius: 5px;
    outline: none;
}

.add_tasks_dialog_title {
    font-size: 22px !important;
    font-weight: 600;
    text-align: center;
    // margin: 5px 0 4px 0px;
    margin: 5px 0px;
}

.required_field {
    color: red;
}

.form_group {
    margin-bottom: 10px;
}

.form_title {
    font-size: 14px !important;
    margin-bottom: 0px !important;
    color: #232f44 !important;
}

.inputStyle {
    height: 46px;
    border: solid 1px #c0c0c0;
    margin-bottom: 8px;
    margin-top: 5px !important;
    width: 100%;
    padding: 5px;
    border-radius: 3px;

}

.inputStyle:focus,
.selectCompany:focus,
.taxtArea:focus {
    outline: none
}

.selectCompany {
    height: 46px;
    margin-top: 5px !important;
    border: solid 1px #c0c0c0;
    width: 100%;
    background-color: white;
    padding: 5px;
    border-radius: 3px;
    -webkit-appearance: none;
    background: url(../../../../assets/images/content-images/select-arrow.png) 95% center no-repeat;
}

.datepicker:first-child {
    width: 100%;
}

.datepicker_select {
    background: none;
}

.saveButton {
    width: 160px;
    height: 46px;
    border-radius: 3px;
    color: white !important;
    background-color: #0b0e22 !important;
    margin: 9px 0 0 0 !important;
    text-transform: capitalize !important;
}

.taxtArea {
    resize: none;
    height: 105px;
    width: 100% !important;
    margin-right: 0px !important;
    border: solid 1px #c0c0c0;
    border-radius: 3px;
    padding: 5px;
    // margin-bottom: 8px;
    margin-bottom: -8px;
    margin-top: 5px !important;
}

.errorMessage {
    font-size: 12px !important;
    color: #cc0000;
}

input:focus {
    outline: none;
}

.submit_loader {
    position: relative;
    width: 85px;
    height: 50px;
}


@media only screen and (max-width: 768px) {
    .add_tasks {width: 100%;} 
} 
.optional {
    color: #9c9bb2;
    font-size: 12px;
    font-weight: normal;
    font-style: italic;
}
@media only screen and (max-width: 767px) {
.taxtArea { height: 60px; }
.add_tasks > div:nth-child(2) {width: 100%;     padding: 30px 15px;} 
.add_tasks_dialog_title { text-align: left; margin-left: 20px;}
}
@media only screen and (max-width: 400px) {
 .add_tasks_dialog_title { font-size: 16px !important; position: relative; top: 0px;}
}