.login {
  width: 400px;
  margin: auto;
  box-sizing: border-box;
  text-align: center;
  vertical-align: middle;
  color: #ffffff;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #00000029;
  opacity: 1;
  height: auto;


  .logo_container {
    padding: 30px;
    width: 100%;
    box-sizing: border-box;

    .logo {
      width: 10rem;
      height: auto;
    }
  }

  .version {
    color: #232F44;
    font-size: 16px;
    font-weight: 600;
  }

  .welcome {
    width: 100%;
    margin-bottom: 45px;

    .heading {
      font-size: 30px;
      font-weight: 300;
      margin: 0 0 10px 0;
      display: inline-block;
      margin-left: 10px;
      color: #232F44;
    }

    .heading_leadx {
      font-size: 30px;
      font-weight: 300;
      margin: 0 0 10px 0;
      font-weight: bold;
      display: inline-block;
      margin-left: 10px;
      color: #232F44;
    }

    h5 {
      color: #232F44;
      font-size: 14px;
      text-align: center;
    }
  }

  .login_form {
    width: 100%;
    margin-bottom: 40px;

    .input_container {
      margin-bottom: 30px;
      width: 100%;
      max-width: 100%;
    }

    .input {
      width: 80%;
      height: 45px;
      border: 1px solid #BDBEC0;
      border-radius: 8px;
      opacity: 1;
      padding: 12px;
      font-style: italic;
      color: #48484F !important;
    }
    .input::after {
      border-bottom: none !important;
    }

    input:-webkit-autofill {
      background-color: #ffffff !important;
      font-style: italic;
    }
    .input::before {
      border-bottom: none !important;
    }

    ::placeholder {
      color: #a1abbb;
      opacity: 1;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #a1abbb;
    }

    .login_button_container {
      display: flex;
      justify-content: center;
      height: 50px;

      .button {
        height: 100%;
        width: 107px;
        background-color: #808080;
        color: #ffffff;
        padding: 13px 34px;
        cursor: pointer;
        border: none;
        font-size: 16px;

        span {
          text-transform: none;
          font-size: 16px !important;
          line-height: normal;
        }
      }

      .button:disabled {
        background-color: grey;
        cursor: not-allowed;
      }
    }
  }

  .forgot_password {
    display: flex;
    margin-bottom: 5px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    span{
      h5 {
        font-size: 16px;
        
        a {
          color: #ffffff !important;
          text-decoration-line: underline;
        }
  
        a:hover,
        a:active {
          color: #17d98b !important;
        }
      }
    }

    h5 {
      font-size: 16px;
      color: #232F44;
    
      a {
        text-decoration-line: underline;
        color: #232F44 !important;
      }
      
      a:nth-child(2) {
        color: #17d98b !important;
        font-weight: bold;
      }
      a:hover,
      a:active {
        color: #17d98b !important;
      }
    }
  }

  .forgot_password > .flex_row_item {
    flex: 1 1 25%; /*grow | shrink | basis */
    // height: 100px;
  }

  .flex_row_first_item {
    //   background-color: #fff4e6;
    //   border: 1px solid #f76707;
    margin-left: 0px !important;
    flex: 1 1 40%;
    text-align: left;
  }

  .flex_row_second_item {
    //   background-color: #fff4e6;
    //   border: 1px solid #f76707;
    margin-left: 35px;
    flex: 1 1 10%;
    text-align: center;
  }

  .flex_row_third_item {
    //   background-color: #fff4e6;
    //   border: 1px solid #f76707;
    margin-left: 0px;
    flex: 1 1 20%;
    text-align: right;
  }
}

.separator {
  // margin: 0px 10px 0px 10px;
  color: gray;
}

.errors {
  color: #cc0000;
  display: block;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@media (max-width: 767px) {
  .login {
    width: 400px;
  }
}

@media only screen and (max-width: 390px) {
  .login {
    max-width: 94%;
  }
}
