.btn_mobile_desktop_hide {
    display: none;
}

.activeNavBar{
    background-color:#d4eed7;
}
:host /deep/ .MuiDrawer-paper-12{
    top: 67px;
}
.logo{
    margin: 0px;
}
.brand > div {
    background-color: #232f44 !important;
    color: white;
    font-size: 20px;    
    padding: 15px 0px 0px 20px;
}
// .brand-item{
//     padding-top:10px !important;
// }
.appBar{
    background-color: #232f44 !important;
    z-index: 1000000 !important;
}
.header{
    width: 105%;
    height: 100%;
}
div.header_bar {
    height: 68px;
    padding-left: 15px !important;
    padding-right: 0px !important;
}
hr{
    background-color: #f1f1f1;
}
div.MuiListItem-button-171:hover{
 background-color: #d4eed7;
}
.icon_image_container{
    width:31px;
    display: inline-block;
}

div.MuiDrawer-paperAnchorDockedLeft-115, div.MuiDrawer-paperAnchorDockedLeft-154{
    border:none;
    z-index: 1000000;
} 

.logout_icon{
    // font-size: 27px;
    color: rgba(255, 255, 255, 0.5);
    // margin-top: 2%;
}
button.header_btn{
    font-size: 14px;
    text-transform: none;
    height: 47px;
    padding:0 8px;
    white-space: nowrap;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
}
button.header_btn:focus {
    border-color: #3a4860 !important;
}

.header_btn:focus, .header_btn:hover {
    border-color: #3a4860 !important;
    box-shadow: none !important;
}
.btn-primary:focus, .btn-primary.focus {
    border-color: #3a4860 !important;
    border:none;
    box-shadow: none !important;
}
button.remove_right_margin {
    margin-right: 0px;
}
.news_btn_wrapper{
    left: 141px;
    top: -57px;
    bottom: -191px;
}

.margin_0{
    margin: 0px 0px 10px 0px !important;
}
.btn_icon{
    display: inline-block;
    height: 22px;
    // width: 23px;
    min-width: 30px;
    width:auto !important;
}
.logout_icon{
    width:22px;
    height: 22px;
    display: inline-block;
    background: url(../../../../assets/images/header-icon/logout-icon.png) center center no-repeat;
}
.creat_new_icon{
    background: url(../../../../assets/images/header-icon/create-new.png) 0 center no-repeat;
    // margin-right:10px;
}
.creat_contact_icon{
    background: url(../../../../assets/images/header-icon/create-contact.png) 0 center no-repeat;
    // margin-right:10px;
}
.create_news_icon{
    background: url(../../../../assets/images/header-icon/forma-1.png) 0 center no-repeat;
    // margin-right:10px;
}
button.creat_btn{
    background-color: #3a4860;  
    margin-bottom: 10px;
    //width: 27%;
    color: #FFFFFF;
    border-color: #3a4860;
}  
button.creat_contact_btn{
    background-color: #3a4860;  
    margin-bottom: 10px;
    //width: 27%;
    border-color: #3a4860;
}
button.creat_btn:hover, button.creat_contact_btn:hover{
    background: #19d98c;
    border-color: #3a4860;
}
.header_icon{
    font-size: 40px !important;
    color: rgba(255, 255, 255, 0.5);
    margin-right: 15px;
    vertical-align: text-bottom;
    border:2px solid #19d98c;
    border-radius: 3px;
    padding: 2px;
}
.user_profile_image{
    display: inline-block;
    position: relative;
}
.user_name{
    color:#fff;
    width: 155px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right:10px;
}
.star_icon{
    height: 20px;
    width:20px;
    display: none;
    background: url(../../../../assets/images/header-icon/star.png) 0 0 no-repeat;
    position: absolute;
    right: 6px;
    top: -6px;
}
.right_header_border.btn_content{
    /* display: flex;
    justify-content: flex-end; */
    padding-top:0;
}
.btn_wrapper{
    position: relative;
    // width:347px;
    padding-top:2px;
    display: inline-block;
    width: 100%;
}
.btn_wrapper:before{
    width: 1px;
    /* height: 100%; */
    left:-13px;
    position: absolute;
    content: '';
    display: inline-block;
    top:0;
    background: #3a4860;
}

.points{
    font-size: 12px !important;
    color: #17d98b;
}

.profile_down_icon{
    display: inline-block;
    float: right;
    vertical-align: top;
    margin:0;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.5);
    position: absolute;
    right:15px;
    cursor: pointer;
}
.right_header_border{
    border-right: 1px solid #3a4860;
    height:100%;
}
div.user_content{
    padding:0px;
    display: flex;
    justify-content: space-between;
}
.header_row{
    height: 100%;
    width:100%;
}
div.MuiToolbar-regular-47{
    padding:0
}
.logout_content{
    background: #3a4860;
    // flex:0 0 63px;
    padding-top: 21px;
    // padding-left: 1px;
    text-align: center;
    cursor: pointer;
    display: block;
    // width: 63px;
    // max-width: 63px;
}
.brand_logo{
    background: url(../../../../assets/new_icons/LeadX_logo_without_slogan_white-01.svg) center center no-repeat;
}
.user_inner_content{
    cursor: pointer;
    display: flex;
    align-items: center;
}
.brand_logo_mobile, .add_icon{
    display: none;
}
.lead-modal{
    max-width: 95% !important;
}
.modal_zindex{
    z-index: 1000 !important;
}
.dialog{
        top:  113px!important;
        left: -265px!important;   
        bottom: 40px;
        /* height : 1180px;   */
         /* padding-right: 20px; */
        padding-left: 25px;  
       /* padding-bottom: 0!important; */
        /* width: 1186px; */
}
.create_news_icon{
    //  margin-right: 5%;
}
.MuiDialog-paperWidthSm-101 {
    max-width: 155%!important;
} 
.profile_popover > div{
   // top: 19px !important;
    // left: -55px !important;
  //  left: -104px !important;
    // height: 40%;

    
    // top: -8px !important;
    // left: -6px !important;
    // width: 480px;
    // max-width: 500px;
    // height: auto;
    // border-radius: 0px;
    // border: 0px;
    // z-index: 99;  
}

ul.left_navigation{
    height: 100%;
    position: fixed;
    box-shadow: 1px 3px 5px #b6b6b8;
    // width: 198px;
    width: 202px;
    padding-top:0;
  }
  .left_navigation .MuiButtonBase-root-55{
    height: 60px;
  }
  
  .left_navigation a[disabled] {
    display: block;
    cursor: not-allowed;
    background-color: inherit;
  }

  
  
  .left_navigation a li {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 62px;
  }
  .left_navigation a:hover{
    text-decoration: none !important;
  }
  
  .left_navigation a:hover li, .left_navigation a:active li {
    background-color: #d4eed7;
  }
  .left_navigation a[disabled]:hover, .left_navigation a[disabled]:hover li {
    background-color: inherit;
  }


  /* .dialogCustomizedWidth > div > div{
    max-width: 800px;
} */
.leadFromPopup > div > div{
    width: 1100px;
}
.brand_logo_aside_left {
    margin-left: 16px;
}
.contactForm > div > div {
    width: 1100px;
}
div.menu_list{
    font-size: 14px !important;
    font-weight: 500!important;;
    font-style: normal!important;;
    font-stretch: normal!important;;
    line-height: 1.29!important;;
    letter-spacing: normal;
    padding:0 0px 0 13px;
    color: rgba(0, 0, 0, 0.87);

}

.disabled_activity span {
    color: #8a919c;
}
/*Microsoft edge browser header fix*/
.header_edge_fix{
    position: fixed;
    top: 0;
    z-index: 999;
}

/* Large screens ----------- */
@media only screen
and (min-width : 1824px) {
    .help {
        margin-left: 15px;
    }
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .help {
        // padding-left: 0rem !important;
        // max-width: 17% !important;
        // margin-left: 8.2%;
        img{
            // width: 28px;
            // margin-top: 4px;
        }
    }
    .logout_content{
        // padding-left: 46px;
        // max-width: 45px;
    }
    .logout_icon {
        // margin-left: -4.4rem;
    }
    .profile_down_icon {
        // right: -13px;
    }
}

/** responisve **/
@media(max-width:1366px){
.profile_popover > div {top: -8px !important; left: -54px !important; z-index: inherit; } 
}
@media(max-width:1300px){
    .profile_popover > div { left: -50px !important; } 
    }
@media(max-width:1024px){
    .profile_popover > div {top: -8px !important; left: -38px !important; } 
    }
@media only screen  and (min-device-width : 768px)  and (max-device-width : 1024px){
    button.header_btn{
        padding:0 8px;
    }
    .btn_wrapper{
        width:307px;
        width:100%;
    }
    button.header_btn span{
        font-size: 12px !important;
    }
    .creat_contact_icon, .creat_new_icon{
        margin-right:3px;
    }
    .logout_content{
        padding-left:16px;
        cursor: pointer;
    }
    button.creat_btn{width: 150px; margin: 8px 2px; }
    button.creat_contact_btn{width: 150px; margin: 8px 2px; }

}
@media(max-width:1024px){
    button.creat_btn{width: auto;}
    button.creat_contact_btn{ width: auto;}
    button.header_btn span{
        font-size: 0px !important;
    }
    button.header_btn span .creat_new_icon,
    button.header_btn span .creat_contact_icon,
    button.header_btn span .create_news_icon{
        margin-right: -4px;
    }
}
@media(max-width:1024px){
.profile_down_icon{right: 7px;}
.btn_icon{ width: 21px;}
}

/* For general iPad layouts */
@media only screen and (device-width: 768px) {
    .user_inner_content{
        max-width: 75% !important;
    }
    .header_icon { 
        margin-right: 4px;
    }
    .user_name { 
        width: 84px;
    }
    div.header_bar {    
        padding-left: 30px !important;
        margin-top: 0px;
        margin-left: 20px;
    }
    .brand_logo_mobile{
        display: block;
        background-position: left center;
    }
    .help {
        max-width: 33.333333% !important;
    }
    .logout_content {
        max-width: 33.333333% !important;
    }
    .brand_logo {
        height: 30px !important;
        margin-top: 15px;
        margin-left: 15px;
    } 
    .tab-logo button {
        left: 178px;
        top: 8px;
    }
}

@media(max-width:767px){
    .btn_mobile_desktop_hide {
        display: inline-block;
        background: #3a4860;
        text-align: center;
        width: 63px;
        max-width: 63px;
        color: #fff;
        border-radius: 0px;
        border: 0px;
        height: 68px;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 2px;
        right: 3px;
    }

    .brand_logo_mobile{
        display: inline-block;
        width: 100px;
        background-size: 100%;
        margin-left: 15px;
    }
    .add_icon{
        display: inline-block;
        height: 33px;
        width: 33px;
        background: url(../../../../assets/images/header-icon/add-icon.png) 0 0 no-repeat;
        background-size: 100%;
    }
    .user_content{
        display: none;
    }
    div.create_content{
        display: none;
    }
    // div.create_content{
    //     display: block;
    // }
   
    .create_box{
        text-align: right;
        display: flex;
        align-items: center;
    }
    .header_bar{
        height: auto;
    }
    .header.mobile_header_content{
        width:100%;
        margin:0;
    }
    div.MuiDrawer-paper-110{
        overflow: hidden;
    }
    .btn_wrapper{
        width:100%;
    }
    .btn_wrapper:before{
        display: none;
    }
    .popup_title{
        margin-bottom:30px;
        margin-top:30px;
        font-size: 20px;
        text-align: center;
        font-weight: bold;
    }
    .mobile_logo_content{
        display: flex;
    }

    button.creat_btn{margin: 0px 16px; vertical-align: top;}
    button.creat_contact_btn{ width: auto;margin: 0px 16px; vertical-align: top; }
    button.header_btn span{
        font-size: 14px !important;
    }
    .leadFromPopup > div > div{
        width: 100%;
        max-width: 100%;
        margin: 48px 20px;
    }


}
@media(max-width:480px){
    button.creat_btn{margin: 0px 0px 16px 0px; width: 100%; vertical-align: top;}
   
}

@media only screen and (max-width: 424px) {
    .add_icon{    position: relative; right: 10px;}
}
@media only screen and (max-width: 414px) {}
@media only screen and (max-width: 400px) {
    .add_icon {  right: 38px;}    
}
@media only screen and (max-width: 414px) {
    .add_icon {  right: 40px;}    
}
@media only screen and (max-width: 384px) {}
@media only screen and (max-width: 375px) {}
@media only screen and (max-width: 360px) {}
@media only screen and (max-width: 320px) {

}

/** responisve **/

.disabled_activity span {
    color: #8a919c;
}
.border_left_header {
    height: 65px;
    width: 1px;
    background-color: #3a4860;
    display: inline-block;
    vertical-align: top;
    margin: 0 10px 0 0;
}

.help {
    background: #3a4860;
    // flex: 0 0 63px;
    padding-top: 16px;
    // padding-left: 12px;
    text-align: center;
    cursor: pointer;
    display: block;
    // width: 63px;
}

.profile_popover > div{
    transform: inherit !important;
    position:fixed !important;
    top:60px !important;
    left:auto !important;
    // transform:translate3d(1366px, 4114px, 0px);
    right:50px !important;
    width:480px;
    max-width:480px;
    z-index:9 !important;
}
.profile_popover .popover .arrow{ display: none;}


.popOverBody {
    padding: 0px !important;
}

.autocompleteDialog {
    color: #232F44;
    font-size: 18px;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;
    text-align: center;
}

.autocompleteDialogTitle h2{
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    color: #232F44;
}

.autocompleteDialogContent {
    width: 450px;
    overflow-y: visible !important;
    background: #F0F0F8 0% 0% no-repeat padding-box;
    // opacity: 1;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.autoComplteInput {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    padding: 0px 8px 0px 8px;
    height: 35px;
    opacity: 1;
    top: 8px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    font-style: italic;
}

.closeButton {
    color: grey;
    right: 3px;
    position: absolute !important;
    top: 2px;
}

.autoComplteLink {
    width: 100%;
    font-weight: bold;
    color: #232F44 !important;
    :hover{
        color: #232F44 !important;
    }
}

.dialogLoadMore {
    p {
       font-size: 18px !important;
       font-weight: bold !important;
       padding: 16px !important;
    }
}

.dialogFooter {
    padding-top: 17px;
    p {
       font-size: 18px !important;
       font-weight: bold !important;
       padding: 16px !important;
    }
    p:nth-child(1) {
        font-family: 'Open Sans', sans-serif !important;
        margin-bottom: 8px;
        font-weight: 400;
        color: #3A4860;
    }
    p:nth-child(2) {
        font-family: 'Open Sans', sans-serif !important;
        font-weight: 700;
        text-decoration: underline;
        color: #3A4860;
    }
}

.popOverButton {
    padding: 10px !important;
   
}

.linkIcon {
    width: 15px;
}

.popupHr {  
    border-top: 3px solid #F0F0F8 !important;
    width: 100%;
    margin-top: 0px !important;
}

.cardRoot {
    box-shadow: none !important;
    text-align: initial;
    padding-bottom: 10px !important;
}

.cardContainer {
    padding: 0px !important;
}

.cardTitle {
    color: #232F44 !important;
    font-size: 17px !important;
    font-weight: bold !important;
}

.cardSubtitle {
    color: #232F44 !important;
    font-size: 12px !important;
}

.cardContent {
    font-size: 14px !important;
    color: #232F44 !important;
    letter-spacing: 0px !important;
    margin-top: 8px !important;
}
.cardButton{
    font-weight: bold !important;
    text-transform: capitalize !important;
    letter-spacing: 0px !important;
    font-size: 17px !important;
    color: #3A4860 !important;
    padding-left: 0px !important;
    span {
        font-size: 17px !important;
    }
    img {
        padding: 4px 8px 0px 8px;
    }
}

.dialogContent {
    border-bottom: 1px solid #F0F0F8;
    overflow-y: initial !important;
}

.tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
        visibility: hidden;
        position: absolute;
        width: 100px;
        font-size: 0.775rem !important;
        background-color: #fff;
        color: #555;
        border: 1px solid #555;
        text-align: center;
        padding: 5px 5px;
        border-radius: 0.3rem;
        z-index: 1;
        transition: opacity .6s;
        top: 113%;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0.3rem;
        margin-left: -27px;
  }

//   .tooltiptext::before {
//     content: "";
//     position: absolute;
//     top: 0;
//     border-width: 0 0.5rem 0.5rem 0.5rem;
//     border-bottom-color: white;
// }

//   .tooltiptext::after {
//     content: "";
//     position: absolute;
//     bottom: 100%;
//     left: 50%;
//     margin-left: -5px;
//     border-width: 5px;
//     border-style: solid;
//     border-color: transparent transparent white transparent;
// }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .firstCardContainer {
    padding: 0px !important;
    border-bottom: 0.5px solid #ccc;
  }

  .secondCardContainer {
    padding: 0px !important;
    margin-top: 15px;
  }