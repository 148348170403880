@import '../variables.scss';

.stepper_container{
    background: #f1f2f8;
    border-radius: 5px;
    height: 40px;
    margin-top:8px;
    overflow: hidden;
}
.stepper_span{
    display: inline-block;
    height: 100%;
    color:#fff;
    width: 20%;
    line-height: 40px;
    text-align: center;
    position: relative;
    vertical-align: top;
    font-size: 14px !important;
    // padding-left: 5%;
}
.stepper_span:after{
	left: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
    pointer-events: none;
    border-width: 20px;
    margin-top: -20px;
}

.stepper_tab_1:after {
	border-left-color: $lead-color;
}
.stepper_tab_2:after {
	border-left-color: $opportunity-color;
}
.stepper_tab_3:after {
	border-left-color: $proposal-color;
}
.stepper_tab_4:after {
	border-left-color: $negotiation-color;
}
.stepper_tab_1{
    background: $lead-color;
    border-radius: 0px 0px 0px 0px;
    
    z-index: 5;
}
.stepper_tab_2{
    background: $opportunity-color;
    z-index: 4;
}
.stepper_tab_3{
    background: $proposal-color;
    z-index: 3;
}
.stepper_tab_4{
    background: $negotiation-color;
    z-index: 2;
}
.stepper_tab_5{
    background: $closed-color;
    border-radius: 0 5px 5px 0;
    z-index: 1;
}
// .greyTab1{
//     background: #141414;
//     border: 1px solid #b9b7b7;
// }
// .greyTab1::after{
//     border-left-color: #141414;
// }
// .greyTab2{
//     background: #383838;
//     border: 1px solid #b9b7b7;
// }
// .greyTab2::after{
//     border-left-color: #383838;
// }
// .greyTab3{
//     background: #6b6a6a;
//     border: 1px solid #b9b7b7;
// }
// .greyTab3::after{
//     border-left-color: #6b6a6a;
// }
// .greyTab4{
//     background: #9b9999;
//     border: 1px solid #b9b7b7;
// }
// .greyTab4::after{
//     border-left-color: #9b9999;
// }
// .greyTab5{
//     background: #dad9d9;
//     border: 1px solid #b9b7b7;
// }
// .greyTab5::after{
//     border-left-color: #dad9d9;
// }

@media(max-width:569px){
.stepper_container{ display: none;}
}