.indraft-card-container{
    padding:0% ;
    display: grid;
    grid-template-columns: 1fr 1fr 200px;
    grid-template-rows: auto;
}
/* .indraft-card-item{
    padding: 1rem;
} */

.action-btn-wrap{
    margin-right: 30px !important;
}

.action-btn{
    margin: 2% !important;
    padding: 1.5% 5% !important;
}

.yes-btn{
    background-color: #19d98c !important;
    color:white !important;
}
