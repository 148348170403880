.checkboxContainer {
    width: 100%;
    .container {
        width: 100%;
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        font-size: 14px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        margin: 0 !important;
    }

    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 4px;
        left: 0;
        width: 14px;
        height: 14px;
        border: solid 1px #232f44;
        background-color: #ffffff;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .container input:checked~.checkmark:after {
        display: block;
    }

    .container label input:checked {
        text-decoration: line-through
    }

    .container .checkmark:after {
        z-index: 2;
        left: 4px;
        top: 0px;
        width: 4px;
        height: 8px;
        border: solid black;
        border-width: 0 1px 1px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .wordBreakClass{
        word-break: break-word;
    }
}

.labelText {
    text-decoration: line-through;
}