.cust_dropdown{
    position: relative;
    box-sizing: border-box;
    outline: none;
  }
  .cust_dropdown_heading_open{
    box-sizing: border-box;
      background-color: rgb(255, 255, 255);
      border-color: rgb(217, 217, 217) rgb(204, 204, 204) rgb(179, 179, 179);
      border-radius: 4px 4px 0px 0px;
      border-width: 1px;
      border-style: solid;
      border-image: initial;
      color: rgb(51, 51, 51);
      cursor: pointer;
      display: table;
      border-spacing: 0px;
      border-collapse: separate;
      height: 36px;
      outline: none;
      overflow: hidden;
      position: relative;
      width: 100%;
  }
  
  .cust_dropdown_heading{
    box-sizing: border-box;
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(204, 204, 204);
      border-radius: 4px;
      color: rgb(51, 51, 51);
      cursor: default;
      display: table;
      border-spacing: 0px;
      border-collapse: separate;
      height: 36px;
      outline: none;
      overflow: hidden;
      position: relative;
      width: 100%;
  }
  .cust_dropdown_heading_value{
    box-sizing: border-box;
    bottom: 0px;
    color: rgb(51, 51, 51);
    left: 0px;
    line-height: 34px;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    right: 0px;
    top: 0px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .cust_dropdown_heading_dropdown_arrow{
    box-sizing: border-box;
    cursor: pointer;
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 25px;
    padding-right: 5px;
  }
  .cust_dropdown_content{
    border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      background-color: rgb(255, 255, 255);
      border-width: 1px;
      border-style: solid;
      border-color: rgb(230, 230, 230) rgb(204, 204, 204) rgb(204, 204, 204);
      border-image: initial;
      box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 0px;
      box-sizing: border-box;
      margin-top: -1px;
      max-height: 300px;
      position: absolute;
      top: 100%;
      width: 100%;
      z-index: 1;
      overflow-y: auto;
  }
  .cust_select_panel{
    box-sizing: border-box;
  }
  .cust_search_box{
    width: 100%;
    box-sizing: border-box;
    padding: 0.5em;
  }
  .cust_search_input{
    display: block;
    max-width: 100%;
    border-radius: 3px;
    box-sizing: border-box;
    height: 30px;
    line-height: 24px;
    border: 1px solid rgb(222, 226, 228);
    padding: 10px;
    width: 100%;
    outline: none;
  }
  .cust_select_list{
    margin: 0px;
    padding-left: 0px;
  }
  .cust_select_li{
    list-style: none;
    
    :hover{
      background-color: rgb(235, 245, 255);
    }
  }
  .cust_select_item{
    background-color: rgb(235, 245, 255);
  }
  .cust_item{
    box-sizing: border-box; 
    display: block; 
    cursor: pointer;
    padding: 5px 0 5px 12px;
    margin: 3px 0 3px 0px;
    margin-bottom: 0px !important;
    // :hover{
    //   box-sizing: border-box; background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); cursor: pointer; display: block; padding: 8px 10px;
    // }
    // :focus{
    //   box-sizing: border-box; background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); cursor: pointer; display: block; padding: 8px 10px;
    // }
  }
  .cust_up_arrow{
    box-sizing: border-box; border-color: transparent transparent rgb(153, 153, 153); border-style: solid; border-width: 0px 5px 5px; display: inline-block; height: 0px; width: 0px; position: relative; top: -2px;
  }
  .cust_dwn_arrow{
    box-sizing: border-box; border-color: rgb(153, 153, 153) transparent transparent; border-style: solid; border-width: 5px 5px 2.5px; display: inline-block; height: 0px; width: 0px; position: relative;
  }
  
  .selectDropdownTitle {
    margin: 0px; 
    font-size: 0.875rem !important;
    font-weight: bold;
    color: #232f44;
    text-align: left;
    width: 100%;
  }
  
  .dropDropMenu {
    min-width: 248px;
    top: 62.5%;
    left: 14.3rem;
  }
  
  .selectOrgOptions {
    background-color: hsl(0,0%,100%);
    border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-width: 1px;
    max-width: 250px !important;
    padding: 0px !important;
    margin: 0 !important;
    height: 2.5em ;
  }
  
  .MuiSelectIcon {
    font-size: 100px;
  }

  .fullWidth {
    max-width: 400px !important;
  }
  
  .desktop_view {
    margin: 0px !important;
  }
  
  /* ----------- iPad 1, 2, Mini and Air ----------- */
  
  /* Portrait and Landscape */
  @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (-webkit-min-device-pixel-ratio: 1) {
      .selectDropdownTitle {
        width: 100%;
      }
  
      .selectOrgOptions {
        width: 100px !important; 
      }
  }
  
  /* Portrait and Landscape */
  @media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) { 
    .selectOrgOptions {
     // max-width: 100% !important; 
    //  width: 50%;
    //  margin-bottom: 5% !important;
    }
  
  }
  @media(max-width:768px){
    // .content-values{ font-size: 36px !important; }    

    .selectDropdownTitle {
      text-align: center !important;
      font-size: 18px !important;
      display: block !important;
      padding-right: 0px !important;
      margin-bottom: 40px;
      //border: 1px solid rgb(204, 204, 204);
    }
    // .mobile_view {
    //   display: block;
    // }
     
    // .desktop_view {
    //   display: none !important;
    // }
  
  
    // .sales-content-text {font-size: 18px !important; margin-top: 0px;}
    // .item-title{ font-size: 14px !important;}
    }
    @media(max-width:753px){
    // .card-title.community-header{ display: block; text-align: center; height: 100%; margin: 16px 0 16px 0; border-bottom: 0px;}
    // .card-title.community-header .title{ display: block; width: 100%; margin: 0px;}
    }
    @media(max-width:767px){
        // .item-title{
        //     width: 170px;
        //     white-space: nowrap;
        //     text-overflow: ellipsis;
        //     overflow: hidden;
        // }
    }
  
  
    