.fields_mapping {
    background-color: #ffffff;
    border-radius: 5px;
    outline: none;
    width: 1069px;
}

.fields_mapping_dialog_title {
    font-size: 22px !important;
    font-weight: 600;
    text-align: center;
    // margin: 5px 0 4px 0px;
    margin: 5px 0px;
    width: 100%;
}

.fields_mapping_dialog_content {
    overflow-x: hidden !important;
    
    // width: 1069px;
    width: 100%;
}

.required_field {
    color: red;
}

.form_group {
    margin-bottom: 15px;

    input:focus {
        outline: none;
    }
}
.form_group input::placeholder {
    color: #9c9bb2 !important;
    font-size: 13px !important;
  }

.form_title {
    font-size: 14px !important;
    margin-bottom: 5px !important;
    color: #232f44 !important;
}

.inputStyle {
    height: 46px;
    border: solid 1px #c0c0c0;
    margin-bottom: 8px;
    margin-top: 5px !important;
    width: 100%;
    padding: 5px;
    border-radius: 3px;
 
}

.inputStyle:focus,
.selectCompany:focus,
.textArea:focus {
    outline: none;
}


.selectCompany {
    cursor: pointer;
    height: 46px;
    margin-top: 5px !important;
    border: solid 1px #c0c0c0;
    width: 100%;
    background-color: white;
    padding: 5px;
    border-radius: 3px;
    -webkit-appearance: none;
    background: url(../../../../assets/images/content-images/select-arrow.png) 99% center no-repeat;
}

.removeBorder {
    border-top: 0 !important;
    padding: 10px !important;
}

.saveButton {
    width: 160px;
    height: 46px;
    border-radius: 3px;
    color: white !important;
    background-color: #0b0e22 !important;
    margin: 9px 0 0 0 !important;
    text-transform: capitalize !important;
    cursor: pointer;
}

.datepicker {
    position: relative;
}

.textArea {
    resize: none;
    height: 46px;
    width: 100% !important;
    margin-right: 0px !important;
    border: solid 1px #c0c0c0;
    border-radius: 3px;
    padding: 5px;
}

.optional {
    color: #9c9bb2;
    font-size: 12px;
    font-weight: normal;
    font-style: italic;
}

.helperText {
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #9c9bb2;
    font-size: 10px !important;
    margin: 7px 0px 0px 0px !important;
    line-height: 14px !important;
}

.dialogTitle {
    width: 100%;
    margin: 0;
    position: relative;
}

.submit_loader {
    position: relative;
    width: 85px;
    height: 50px;
}
/* 
.fields_mapping_container {
    width: 100%;
    border-radius: 5px;
    background-color: #ffffff;
    color: #1c2535;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    hr {
        margin: 0;
    }
} */
/* 
.fields_mapping {
    width: 100%;
    height: 54px;
    background-color: #232f44;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.fields_mapping_container_heading {
    font-size: 16px;
    color: #ffffff;
    margin: 0;
}

.fields_mapping_container_actions {
    display: flex;
    justify-content: center;
    position: relative;
} */

@media only screen and (max-width: 1024px) {
.fields_mapping{ width: 100%; overflow-x: hidden;}    
   
}
@media only screen and (max-width: 767px) {
.fields_mapping_dialog_title { text-align: left; margin-left: 20px;}
.fields_mapping_dialog_content { padding: 15px !important;  position: relative;}

}
@media only screen and (max-width: 400px) {
.fields_mapping_dialog_title { font-size: 16px !important; position: relative; top: 0px;}
}