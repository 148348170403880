
.unread_item{
    // background: #647491 !important;
    background: url('../../../../assets/images/feeds-page/unread_won_background_regular.png') no-repeat center !important;
    // background-size: 105% !important;
    background-size: 100% !important;
}
.unread_won_item{
    background: url('../../../../assets/images/feeds-page/unread_won_background_new.png') no-repeat center !important;
    // background-size: 105% !important;
    background-size: 100% !important;
}
.nonsales_grid_card{
    background: #232f44 !important;
}
.feed_img{
    vertical-align: text-top !important;
}

.container-fluid > .row{
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.grid-user-name{
    margin-left: 16px
}
.newsTime{
  height: 12px;
  font-family: 'OpenSans';
  font-size: 12px !important;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #a7abb4;
}

.spanClass{
    -webkit-line-clamp: 2;
    font-size: 14px !important;
    font-weight: 600 !important;
    font-style: normal !important;
    font-stretch: normal !important;
    line-height: 1.43 !important;
    letter-spacing: normal !important;
    word-wrap: break-word;
    width: inherit;
    overflow: hidden;
    // height: 3em;
    white-space: normal !important;
    display: -webkit-box !important;
    word-break: break-word;
}
// .rowSpacing{
//     margin-right:0px;
// }
// .colMargin{
//     padding-right: 15px !important;
// }

/** media query *****/
@media(max-width:1366px){
    .unread_won_item{    background-size: 100% !important;}
}
@media(max-width:1300px){
    .unread_won_item{    background-size: 100% !important;}
}

@media(max-width:1366px){
    .unread_item{    background-size: 100% !important;}
}
@media(max-width:1300px){
    .unread_item{    background-size: 100% !important;}
}

@media(max-width:767px){
    .notification_inner_grid{
        height: 100%;
        padding-bottom:10px;
    }
 
}

@media(max-width:400px){
    .unread_won_item{
        background-size: inherit !important;       
    }    
}
@media(max-width:400px){
    .unread_item{
        background-size: inherit !important;
    }
}
/** media query *****/