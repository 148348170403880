.popover_content {
	// width: 576px;
	width: 700px;
	padding: 20px 20px 0 20px;
	overflow-x: hidden;
}

.pipeline_border {
	border-top: 1px solid #eaebf1;
	margin-top: 20px;
	padding-top: 20px;
}

.handIcon {
	text-align: end;
//     position: absolute;
//     right: -150px;
//     top: -10px;
}


.listContent {
	margin-top: 6% !important;
  }
  
  .listTitle {
	font-weight: bold !important;
	color: #232F44;
	font-size: 20px !important;
	letter-spacing: 0px !important;
	margin-bottom: 0px;
  }
  
  .listBottom {
	width: 100%;
	margin-bottom: 0px;
	border: 1px solid #DADADA;
  }
  
  .listText {
	font-size: 14px !important;
	color: #3A4860;
  }
  
  .confirmText {
	font-size: 18px !important;
	font-weight: bold !important;
	color: #232F44;
	letter-spacing: 0px !important;
	margin-bottom: 0px;
  }
  
  .cancleBtn {
	color: #3A4860 !important;
	font-weight: 700 !important;
	text-transform: capitalize !important;
	letter-spacing: 0px !important;
  }
  
  .deleteBtn {
	background: #3A4860 !important;
	color: #ffffff !important;
	text-transform: capitalize !important;
  }
  
.deleteLead {
	cursor: pointer;
	img {
	  width: 18px;
	}
	span {
	  font-size: 12px !important;
	  color: #3A4860;
	  padding: 6px;
	  letter-spacing: 0px;
	  font-weight: bold;
	}
  }
  .dialogAct {
	padding: 24px !important;
  }

.user_name,
.lead_name,
.company_name {
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.assigned_to {
	display: inline-block;
	max-width: 250px;
	vertical-align: top;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-top: 0px;
	margin-left: 5px;
}

.popover_content_title {
	display: inline-block;
	vertical-align: top;
}

.italic {
	font-style: italic;
}

/*** media query **/
@media only screen and (max-width: 767px) {
	.handIcon {
		text-align: left;
		margin-top: 20px;
		right: unset;
		top: unset;
	}
}

@media only screen and (max-width: 533px) {
	.popover_content {
		width: 100%;
	}
}

@media only screen and (max-width: 400px) {
	.user_name,
	.lead_name,
	.company_name {
		max-width: 100%;
	}
}

/*** media query **/

.deleteLead {
  float: right;
	cursor: pointer;
	padding-right: 18px;
  img {
    width: 18px;
  }
  span {
    font-size: 12px !important;
    color: #3A4860;
    padding: 6px;
    letter-spacing: 0px;
    font-weight: bold;
  }
}