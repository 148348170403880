.page_title {
  margin: 0px;
  font-size: 1.25em;
  font-weight: bold;
  color: #232f44;
  text-align: left;
}

.noHorizontalMargin {
  margin-left: 0px;
  margin-right: 0px;
}

.licenseTypeOptionContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  padding-left: 0px;
}

.searchInputContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  padding-right: 0px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .licenseTypeOptionContainer {
    padding-left: 5px;
  }

  .searchInputContainer {
    padding-right: 5px;
  }
}
