.calenderDownArrowIcon {
  margin-left: 5px;
  margin-right: -5px;
  margin-top: 2px;
  cursor: pointer;
}

.datePicker {
  padding-top: 7px !important;
}

.daterangepicker {
  top: 50px !important;
  margin-top: 0px !important;
  right: 15px !important;
  width: max-content !important;
  z-index: 100 !important;
}

// #19D98B
.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #19D98B !important;
}

// #dcfadc
.daterangepicker td.in-range {
  background-color: #dcfadc !important;
}

.btn-primary {
  background-color: #394860 !important;
  border-color: #394860 !important;;
}