.lead_dialog_content {
  padding: 24px !important;
  //padding: 16px !important;
  overflow-y: hidden !important; 
}

.lead_dialog_title_container {
  text-align: center;
}

.dialog_title {
  font-size: 22px !important;
  font-weight: 600;
  text-align: center;
  // margin: 5px 0 4px 0px;
  margin: 5px 0px;
}

.tabsPadding {
  // margin-bottom: 22px;
  height: 46px;
  display: flex;
  justify-content: center;
  span {
    background-color: inherit;
  }
  margin-top: 30px;
}

.tabsDesign {
  width: 180px !important;
  color: black !important;
  margin-right: 1px !important;
  border: 2px solid black !important;
  border-radius: 3px !important;
  height: 46px !important;
  min-height: 46px !important;
  // text-transform: capitalize !important;
  text-transform: none !important;
  padding: 0px !important;
}

.tabNotselected {
  border: 2px solid #eaebf1 !important;
  padding: 0px !important;
  width: 180px !important;
  margin: 0px 10px !important;
  text-transform: capitalize !important;
}

.companyTab {
  margin-left: 9px !important;
  border: 2px solid rgb(230, 226, 226) !important;
  border-radius: 3px !important;
  height: 46px !important;
  min-height: 46px !important;
  text-transform: capitalize !important;
}

.personTab {
  margin-right: 9px !important;
  border: 2px solid rgb(230, 226, 226) !important;
  border-radius: 3px !important;
  height: 46px !important;
  min-height: 46px !important;
  text-transform: capitalize !important;
}

.inputStyle {
  height: 46px;
  border: solid 1px #c0c0c0;
  // margin-bottom: 8px;
  margin-bottom: 0px;
  margin-top: 5px !important;
  width: 100%;
  padding: 5px;
  border-radius: 3px;

}

.label {
  font-size: 14px !important;
  color: #232f44;
  margin-bottom: 0px !important;
}

.taxtArea {
  //width:1052px;
  // height: 80px !important;
  resize: none;
  width: 100% !important;
  margin-right: 0px !important;
  border: solid 1px #c0c0c0;
  padding: 5px;
  border-radius: 3px;
  margin-top: 5px;
}

.taxtArea:focus {
  outline: none;
}

.saveButton {
  width: 160px;
  height: 46px;
  object-fit: contain;
  border-radius: 3px;
  color: white !important;
  background-color: #0b0e22 !important;
  margin-top: -30px !important;
  margin-bottom:16px !important;
  text-transform: capitalize !important;
  margin-right:20px !important;
}

.saveButton:disabled {
  color: #8a919c !important;
  background-color: #f5f5f5 !important;
  border: #eaebf1 !important;
}

.contact_person_add_button {
  width: 100%;
  height: 46px;
  object-fit: contain;
  border-radius: 3px;
  padding: 5px;
  margin-top: 24px !important;
  text-transform: none !important;
}

.contact_person_add_button_icon {
  margin-right: 10px;
  display: inline-block;
  height: 22px;
  width: 28px;
}

.contact_person_add_button_icon_open {
  background: url("../../../assets/contact_company_person_add.png") 0 center no-repeat;
}

.contact_person_add_button_icon_close {
  background: url("../../../assets/contact_company_person_remove.png") 0 center no-repeat;
}

.contact_person_add_button_open {
  color: #0b0e22 !important;
  border: solid 1px #0b0e22 !important;
}

.contact_person_add_button_close {
  color: #aaaaaa !important;
  border: solid 1px #aaaaaa !important;
}

.contact_person_add_button:hover {
  background-color: inherit !important;
}

.add_contact {
  margin-right: -24px !important;
  margin-left: -24px !important;
  padding: 10px !important;
  background-color: #e5e6ea;
}

.selectCompany {
  height: 46px;
  margin-top: 5px !important;
  border: solid 1px #c0c0c0;
  width: 100%;
  background-color: white !important;
  padding: 5px;
  border-radius: 3px;
  -webkit-appearance: none;
  background: url(../../../assets/images/content-images/select-arrow.png) 95% center no-repeat;
}


.selectCompany[value="0"] {
  color: #9c9bb2;
} 

.removeBorder {
  border-top: 0 !important;
  padding: 8px 24px;
}

.errorMessage {
  font-size: 12px !important;
  color: #cc0000;
}

.companyDropdown {
  input {
    height: 46px;
    margin-top: 5px !important;
    border: solid 2px #c0c0c0;
    width: 262px;
    background-color: white;
  }
}
.required_field{
  color: #cc0000;
}

.form-group input::placeholder {
  color: #9c9bb2 !important;
  font-size: 13px !important;
}

.input_website{
  width: 80%;
  border-left: none;
  padding-left: 0px;
  
}
 
@media only screen and (max-width: 767px) {
  .input_website{ width: 92%;}
  .contact_person_add_button { margin-top: 0 !important; }
}
@media only screen and (max-width: 711px) {
  .input_website{ width: 91%;}
}
@media only screen and (max-width: 667px) {
  .input_website{ width: 90%;}
  .dialog_title {text-align: left; margin: 5px 0 4px 12px;}
}
@media only screen and (max-width: 640px) {
  .input_website{ width: 90%;}
}
@media only screen and (max-width: 640px) {
  .input_website{ width: 89%;}
}
@media only screen and (max-width: 569px) {
  .input_website{ width: 88%;}
}
@media only screen and (max-width: 553px) {
  .input_website{ width: 87%;}
}

@media only screen and (max-width: 480px) {
  .tabsPadding { display: block; padding: 0 10px;}
  .input_website{ width: 85%;}

}
@media only screen and (max-width: 424px) {
  .input_website{ width: 82%;}
}
@media only screen and (max-width: 414px) {
  .input_website{ width: 82%;}
}
@media only screen and (max-width: 400px) {
  .input_website{ width: 81%;}
}

@media only screen and (max-width: 390px) {
  .input_website{ width: 80%;}
  .dialog_title { font-size: 18px !important;}
}
@media only screen and (max-width: 375px) {
  .input_website{ width: 80%;}
  
 }
@media only screen and (max-width: 360px) {
  .input_website{ width: 79%;}
}
@media only screen and (max-width: 320px) {
  .input_website{ width: 75%;}
}