.title_right_text h3{
    font-size: 20px;
    font-weight: 600;
    color: #232f44;
}

.navbar {
    padding: 0rem;
    text-align: end;
    span {
        font-weight: 700;
        cursor: pointer;
    }
    Button {
        color: #fff !important;
    }
}

.tableContainer {
    box-shadow: none !important;
    background: none !important;
}

.MuiTableCellHead {
    font-weight: bold !important;
    border-bottom: none !important;
    text-align: center !important;
    font-size: 18px !important;
    font-family: none !important;

    hr{
        width: 230px;
        border-top: 2px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 0px;
    }
}

.MuiTypography {
    text-align: center;
    h2 {
        font-weight: 600 !important;
        color: #3A4860;
    } 
}

.formControl 
    label {
        font-weight: bold;
        font-size: 15px;
        color: #3A4860;
    }
    
.gridControl 
    input {
        height: 8px;
        width: 300px;
    }
    .gridControlBtn {
        background-color: #3A4860 !important;
        color: #fff !important;
    }
    .copyIcon {
        padding: 9px;
        cursor: pointer;
    }
    

.closeButton {
    color: #3A4860;
    cursor: pointer;
    float: right;
}

.eyeIcon {
    cursor: pointer;
    width: 30px;
}

.eyeIconOnRegisterApplication{
    cursor: pointer;
    width: 25px;
    margin-left: -35px !important;
    z-index: 1 !important;
}

.MuiTableCellBody {
    padding-left: 28px !important;
}