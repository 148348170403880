.activities_container_body {
  overflow-y: auto;
  height: 410px;
  width: 100%;
}

.date_container {
  padding: 10px;

  .date {
    margin: 0;
    font-size: 14px;
    color: #232f44;
  }
}

.notes {
  width: 100%;
  padding: 10px;
}

.note {
  padding: 19px;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #ddf2e1;
  border-left: 5px solid #52b660;
}

.description {
  color: #1c2535;
  font-family: Montserrat;
  font-size: 12px;
  margin-bottom: 20px;
}

.time {
  color: #9c9bb2;
  font-size: 16px;
}

.footer {
  display: flex;
  justify-content: space-between;
}
.name {
  font-size: 16px;
  font-weight: bold;
}

.department {
  font-style: italic;
}
.wordBreakClass {
  word-break: break-word;
}
