.title_Administration {
    margin: 0px;
    font-size: 1.25em;
    font-weight: bold;
    color: #232f44;
    text-align: left;
  }
  
  .title_text {
    margin-top: 1.25em;
    font-weight: 650;
  }
  
  .request_title {
    display: flex;
    align-items: center;
  }
  
  .title_right_text {
    margin-top: 1.875em;
    font-weight: 650;
    font-size: 1rem !important;
  }
  
  
  #pendingusers td{
    padding: 10px !important;
  }
  
  .org_profile_logo {
    margin-top: 2.145em;
    width: 12.5em;
  }
  
  .card_view {
    margin-top: 0.625em;
  }
  
  .bottom_update_button {
    text-align: right;
    margin-top: 4.375em;
    margin-bottom: 4.375em;
  }
  
  .title_text {
    margin-top: 1.25em;
    font-weight: 650;
  }
  
  .title_right_text {
    margin-top: 1.875em;
    font-weight: 650;
    font-size: 1rem !important;
  }
  
  .Select {
    width: 27.75em !important;
    margin-right: 0.3125em;
    z-index: 0 !important;
  }
  
  .Select.is-open {
  z-index: 1 !important;
  }
  
  .Select-menu {
    position: fixed !important;
    width: 27.5em !important;
    margin: 0.0625em;
  }

  .containerCustom {
    position: relative;
    display: inline-block;

    .middle {
      position: absolute;
      top: 60%;
      left: 85%
    }
  }
  
  .image {
    height: auto;
  }
  
  
  .border-radius-50{
    border-radius: 50% !important;
  }
  
  .rounded{
      left: 40%;
      position: absolute;
      top: 63%;
      background: #3a4860;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center; 
      text-align: center;
      cursor: pointer;   
  };

  .red{
    color: red;;
  }
  .green{
    color: green;
  }

  .status_active{
    color: #19d98c;
  }

  .status_inactive{
    font-size: 12px;
    color:#cc0000;
  }