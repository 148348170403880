.title_Administration {
  margin: 0px;
  font-size: 1.25em;
  font-weight: bold;
  color: #232f44;
  text-align: left;
}

.title_text {
  margin-top: 1.25em;
  font-weight: 650;
}

.request_title {
  display: flex;
  align-items: center;
}

.search_box {
  text-align: right;
}

.title_right_text {
  margin-top: 1.875em;
  font-weight: 650;
  font-size: 1rem !important;
}

@media(max-width:768px){
  .search_box {
    text-align: center;
  }
}

#pendingusers td{
  padding: 10px !important;
}

.top_container {
  display: flex;
  justify-content: flex-end;
  .orgName {
    display: flex;
    align-items: center;
    padding-right: 10px;
    font-weight: bold;
  }
  .separator {
    margin: 0px 10px 0px 10px;
    color: gray;
    display: flex;
    align-items: center;
    padding-right: 10px;
    font-weight: bold;
  }
  .button {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 0px
  }
}

.popup_title {
  text-align: center;
  font-size: 1.5625rem !important;
  font-weight: bold;
  padding-top: 7px;
}

TableCell {
  text-align: left;
}