.popup_dialogbox > div > div {
	width: 640px;
}

.crop_image_title {
	font-size: 22px !important;
	font-weight: 600;
	text-align: center;
	margin: 5px 0px;
}

.cropper {
	width: 100%;
	margin-bottom: 20px;
}

.cropButton {
	width: 160px;
	height: 46px;
	border-radius: 3px;
	color: white !important;
	background-color: #0b0e22 !important;
	margin: 9px 0 0 0 !important;
	text-transform: none !important;
}

/* Responisve */
@media only screen and (max-width: 768px) {
}
@media only screen and (max-width: 767px) {
}
@media only screen and (max-width: 753px) {
}
@media only screen and (max-width: 711px) {
}
@media only screen and (max-width: 667px) {
}

@media only screen and (max-width: 640px) {
	.cropper-container {
		width: 100% !important;
	}
}
@media only screen and (max-width: 600px) {
}
@media only screen and (max-width: 569px) {
}
@media only screen and (max-width: 533px) {
}

@media only screen and (max-width: 480px) {
}
@media only screen and (max-width: 424px) {
}
@media only screen and (max-width: 414px) {
}
@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 390px) {
}
@media only screen and (max-width: 375px) {
}
@media only screen and (max-width: 360px) {
}
@media only screen and (max-width: 320px) {
	.cropper-container {
		width: 100% !important;
	}
}
/* Responisve */
