.gridUserName{
    margin-left: 10px !important;
    /* display: inline-block !important; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 154px;
    /* flex: 0 0 auto; */
    color: #fff;
    font-family: 'OpenSans';
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    /* line-height: 1.71; */
    letter-spacing: normal;
    /* text-align: left; */
    color: #232f44;
    
}

.info {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.createdAt{
    margin-top: 7px  !important;
    float: right;
    display: inline-block !important;
    font-size: 12px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #9c9bb2 !important;
}
.organizationName {
    color: #9c9bb2 !important;
    font-size: 12px !important;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    margin-left: 10px !important;
    text-overflow: ellipsis;
    width: 154px;
    display: block;
}

.gridInnerHead{
    display: flex;
    align-items: center;
}
/* .sales-container .grid-icon-container, .sales-container .gridUserName{
    flex: 0 0 auto;
} 
.right-content-inner .grid-icon-container img {
    margin-right: 21px;
} */
.wrapProfileImage{
    margin: 10px 20px 10px 20px;
    /* display: block !important; */
    display: flex;
    width: 100%
}
.profileImage {
    width: 36px;
    height: 36px;
    border-radius: 3px;
}

.cardAction{
    padding: 0px 0px !important;
}
.btnContainer{
    margin:0 0px !important;
}
.btnAll{
    text-transform: none!important;
    border-radius: 0px !important;
    border:1px solid #e8e3e3 !important;
    padding: 13px !important;
    font-size: 14px !important;
    color: #9c9bb2 !important;

    
}

.btnAll:hover{
    font-weight: 600 !important;
    color:black !important;

}


.gridBanner{
    margin:10px -20px;
}
.salesNewsImage {
    height: 150px;
}

.gridText {
    -webkit-line-clamp: 2;
    color: #232f44 !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    font-style: normal !important;
    font-stretch: normal !important;
    line-height: 1.43 !important;
    letter-spacing: normal !important;
    margin: 5px 0px 0px 15px !important;
    word-wrap: break-word;
    width: inherit;
    overflow: hidden;
    height: 3em;
    -webkit-box-orient: vertical !important;
    white-space: normal !important;
    display: -webkit-box !important;
   
}

.dateText {
    -webkit-line-clamp: 2;
    color: #232f44 !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-stretch: normal !important;
    line-height: 1.43 !important;
    letter-spacing: normal !important;
    margin: 0px 0px 0px 15px !important;
    word-wrap: break-word;
    width: inherit;
    overflow: hidden;
    height: 3em;
    -webkit-box-orient: vertical !important;
    white-space: normal !important;
    display: -webkit-box !important;
   
}
.cardContainer_anch{
    margin: 15px;
}
.cardContainer:hover{
    cursor: pointer;
    box-shadow: 0px 0px 9px gray;
}
.news-time{
    
    font-size: 12px !important;
    color: #9c9bb2 !important;
}