.main_wrapper {
    padding: 20px 30px;
}

.integrations_container {
    width: 100%;
    border-radius: 5px;
    background-color: #ffffff;
    color: #1c2535;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    hr {
        margin: 0;
    }
}

.integrations_container_header {
    width: 100%;
    height: 54px;
    background-color: #232f44;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.integrations_container_heading {
    font-size: 16px;
    color: #ffffff;
    margin: 0;
}

.integrations_container_actions {
    display: flex;
    justify-content: center;
    position: relative;
}

.calendar_container {
    display: flex;
    justify-content: flex-end;

    .buttons {
        width: 66px;
        background-color: #ffffff;
        border: solid 2px #eaebf1;
        color: #9c9bb2;
        border-radius: 3px;
        margin-left: 10px;
        height: 46px;
    }

    .selected {
        border: solid 2px #232f44;
        color: #232f44;
    }

    .date_picker_icon {
        background: url(../../../../../src/assets/images/content-images/calender.png) 0 0 no-repeat;
        width: 22px;
        height: 21px;
        display: inline-block;
        // position: absolute;
        // right: 35px;
        // top: 38px;
        pointer-events: none;
    }
}

.centerAlign {
    align-items: center !important;
}

@media only screen and (max-width: 480px) {
.calendar_container{ margin-top: 20px;}
}

.jss201 {
    height: 1px;
    margin: 0;
    border: none;
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 0.12);
}

.profileContainer_user_avatar_section__2pI8B {
    padding: 15px 30px;
    text-align: center;
    margin-bottom: 0!important;
}

.profileContainer_update_btn__MXNDH {
    padding: 10px 50px;
    background-color: #232f44;
    color: #fff;
    font-size: 14px!important;
    border: none;
    border-radius: 3px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.profileContainer_deactivate {
    padding: 10px;
    background-color: grey;
    color: #fff;
    font-size: 14px!important;
    border: none;
    border-radius: 3px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.profileContainer_update_btn__MXNDH {
    // padding: 10px 50px;
    background-color: #232f44;
    color: #fff;
    font-size: 14px!important;
    border: none;
    border-radius: 3px;
    // margin-top: 15px;
    // margin-left: 1rem!important;
    // margin-bottom: 1rem!important;
    // margin-right: 1rem!important;
    // margin-top: 1rem!important;
    padding:10px;
}

.grey_image {
    -webkit-filter:  grayscale(100%) brightness(60%) contrast(10000000%);
    opacity: .3;
}

.ml-3, .mx-3 {
    margin-left: 1rem!important;
}

.mb-3, .my-3 {
    margin-bottom: 1rem!important;
}

.mr-3, .mx-3 {
    margin-right: 1rem!important;
}

.mt-3, .my-3 {
    margin-top: 1rem!important;
}

.checkmark {
    display:inline-block;
    width: 22px;
    height:22px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
}

.checkmark_circle {
    position: absolute;
    width:22px;
    height:22px;
    background-color: #55C63A;
    border-radius:11px;
    left:0;
    top:0;
}

.checkmark_circle_grey {
    position: absolute;
    width:22px;
    height:22px;
    background-color: lightgray;
    border-radius:11px;
    left:0;
    top:0;
}

.checkmark_stem {
    position: absolute;
    width:3px;
    height:9px;
    background-color:#fff;
    left:11px;
    top:6px;
}

.checkmark_kick {
    position: absolute;
    width:3px;
    height:3px;
    background-color:#fff;
    left:8px;
    top:12px;
}

.new_integration{
    width: 14px;
    height: 13px;
    /*background: transparent url(../../../../../src/assets/images/content-images/new_star.png) 0 0 no-repeat;;*/
    opacity: 1;
    border:0px;
    margin-bottom: 4px;
    margin-right: 5px;
}

