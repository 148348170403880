.cust_dropdown{
  position: relative;
  box-sizing: border-box;
  outline: none;
}
.cust_dropdown_heading_open{
  box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    border-color: rgb(217, 217, 217) rgb(204, 204, 204) rgb(179, 179, 179);
    border-radius: 4px 4px 0px 0px;
    border-width: 1px;
    border-style: solid;
    border-image: initial;
    color: rgb(51, 51, 51);
    cursor: pointer;
    display: table;
    border-spacing: 0px;
    border-collapse: separate;
    height: 36px;
    outline: none;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.cust_dropdown_heading{
  box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    color: rgb(51, 51, 51);
    cursor: default;
    display: table;
    border-spacing: 0px;
    border-collapse: separate;
    height: 36px;
    outline: none;
    overflow: hidden;
    position: relative;
    width: 100%;
}
.cust_dropdown_heading_value{
  box-sizing: border-box;
  bottom: 0px;
  color: rgb(51, 51, 51);
  left: 0px;
  line-height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  right: 0px;
  top: 0px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cust_dropdown_heading_dropdown_arrow{
  box-sizing: border-box;
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 25px;
  padding-right: 5px;
}
.cust_dropdown_content{
  border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: rgb(255, 255, 255);
    border-width: 1px;
    border-style: solid;
    border-color: rgb(230, 230, 230) rgb(204, 204, 204) rgb(204, 204, 204);
    border-image: initial;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 0px;
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 300px;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1;
    overflow-y: auto;
}
.cust_select_panel{
  box-sizing: border-box;
}
.cust_search_box{
  width: 100%;
  box-sizing: border-box;
  padding: 0.5em;
}
.cust_search_input{
  display: block;
  max-width: 100%;
  border-radius: 3px;
  box-sizing: border-box;
  height: 30px;
  line-height: 24px;
  border: 1px solid rgb(222, 226, 228);
  padding: 10px;
  width: 100%;
  outline: none;
}
.cust_select_list{
  margin: 0px;
  padding-left: 0px;
}
.cust_select_li{
  list-style: none;
  
  :hover{
    background-color: rgb(235, 245, 255);
  }
}
.cust_select_item{
  background-color: rgb(235, 245, 255);
}
.cust_item{
  box-sizing: border-box; 
  display: block; 
  cursor: pointer;
  padding: 5px 0 5px 12px;
  margin: 3px 0 3px 0px;
  margin-bottom: 0px !important;
  // :hover{
  //   box-sizing: border-box; background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); cursor: pointer; display: block; padding: 8px 10px;
  // }
  // :focus{
  //   box-sizing: border-box; background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); cursor: pointer; display: block; padding: 8px 10px;
  // }
}
.cust_up_arrow{
  box-sizing: border-box; border-color: transparent transparent rgb(153, 153, 153); border-style: solid; border-width: 0px 5px 5px; display: inline-block; height: 0px; width: 0px; position: relative; top: -2px;
}
.cust_dwn_arrow{
  box-sizing: border-box; border-color: rgb(153, 153, 153) transparent transparent; border-style: solid; border-width: 5px 5px 2.5px; display: inline-block; height: 0px; width: 0px; position: relative;
}
.indraft-card-item {
  padding: 15px !important;
  background-color: blue($color: #000000);
}

.handoverCrossRow {
  justify-content: space-between;
  padding-right: 0px;
}

.rowJustify {
  justify-content: flex-end;
}

.clearAll {
  max-width: 8%;
  margin-top: 6px;
}   

.activities_container {
  width: 100%;
  border-radius: 5px;
  background-color: #ffffff;
  color: #1c2535;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  hr {
    margin: 0;
  }
}

.activities_container_header {
  width: 100%;
  height: 54px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.crossSalesChartHeader {
  margin-top: 50px;
  font-weight: 700;
  margin-left: 20px;
}
.crossSalesChartSubHeader {
  margin-left: 20px;
}

.activities_container_heading {
  font-size: 20px;
  color: #232F44;
  margin: 0;
  font-weight: bold;
  font-family: sans-serif;
  letter-spacing: 0px;
  margin: 10px 0px 10px 0px;
}

.MuiInput-underline-222 {
  border-bottom: none !important;
}

.chart_container {
  .MuiInputBase-root-231 {
    border-bottom: none !important;
    color: #fff !important;
  }
}

.tile_headings {
  font-family: "Open Sans", sans-serif !important;
  font-size: 0.875rem !important;
}

.grid_title_text {
  font-size: 16px;
  color: #ffffff;
}

.cardHeader {
  padding: 20px;
}

.table_container {
  .MuiPaper-elevation2-29 {
    box-shadow: none !important;
  }
}

.rewards_button {
  min-width: 250px;
  max-width: 250px;
  /* text-overflow: clip; */
  max-height: 100px;
  margin-bottom: 10px;
}

.title_Pulselite {
  margin: 0px;
  font-size: 20px;
  font-weight: bold;
  color: #232f44;
  text-align: left;
}

.image_cropper {
  display: inline-flex;
  width: 115px;
  height: 115px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  background-color: white;
}

.icon_pic {
  // max-width: 35px;
  height: auto;
  width: 138px;
}

.ahref {
  font-size: 12px;
  color: #3A4860 !important;
  text-decoration: underline !important;
  font-weight: bold;
  cursor: pointer;
  margin-left: 10px;
}

.ahref:hover {
  color: #3A4860 !important;
}

.content_values1 {
  color: #17d98b;
  font-weight: 300;
  font-size: 36px !important;
}

.first_content_text {
  font-size: 18px !important;
  margin-top: -8px;
  color: #232f44 !important;
  font-weight: 700;
}

.selectDropdownTitle {
  margin: 0px; 
  font-size: 0.875rem !important;
  font-weight: bold;
  color: #232f44;
  text-align: left;
  width: 100%;
}

.dropDropMenu {
  min-width: 248px;
  top: 62.5%;
  left: 14.3rem;
}

.tableLink {
  text-decoration: underline;
}

.selectOrgOptions {
  background-color: hsl(0,0%,100%);
  border-color: hsl(0,0%,80%);
  border-radius: 4px;
  border-width: 1px;
  max-width: 250px !important;
  padding: 0px !important;
  margin: 0 !important;
  height: 2.5em ;
}

.MuiSelectIcon {
  font-size: 100px;
}

.desktop_view {
  margin: 0px !important;
}

.pieChartContainer {
  margin-left: 0px;
  margin-right: 0px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
  border-radius: 4px;
  opacity: 1;
}

.pieMonthData ul{
  column-count: 2;
  list-style-type: none;
}

.col-xs-8 {
  width: 70% !important;
}
.oval {
  width: 20px;
  height: 20px;
  border-radius: 10;
  background-color: 'red';
}

.svgOval {
  width: 35;
  height: 20;
}

.pieMonthSpace {
  margin-left: 7px;
  margin-right: 5px;
  font-size: 10px!important;
  padding-top: 10px;
}

.styled-select select {
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  background-image: none;
}

.pieChartPerMonth {
  padding-right: 5px;
  font-weight: bold;
  font-size: 14px !important;
  padding-top: 10px;
  color: #707070;
  width: 70px;
}

.pieHeaderValue {
  font-size: 24px !important;
  padding-top: 15px;
  font-weight: bold;
  color: #CC5869;
  margin-bottom: 0px;
}

.pieSubHeaderTital {
  font-size: 16px !important;
  padding-bottom: 15px;
  font-weight: bold;
}

.pieFooterTitle {
  padding: 15px;
  font-size: 14px !important;
  color: #707070;
}

.pieRightTitle {
  font-size: 14px !important;
    padding-top: 20px;
    padding-bottom: 5px;
    padding-left: 20px;
    font-weight: bold;
    color: #6C6B77;
}

.pieMonthTitleData li{
  font-size: 14px !important;
    padding-top: 10px;
    color: #707070;
}

.topSection {
  display: flex;
  justify-content: center;
  /* align-self: center; */
  /* align-items: center; */
  flex-direction: column;
}

.topSectionLeft {
  display: flex;
  justify-content: center;
  /* align-self: center; */
  align-items: center;
  flex-direction: column;
}

.topSectionLeft .icon_img {
  width: 138px;
}

.col-xs-2 {
  width: 30% !important;
}

.col_xs_8 {
  width: 70% !important;
}

.pipelineTopSection {
  display: flex;
  justify-content: center;
  /* align-self: center; */
  align-items: center;
  flex-direction: column;
  max-width: 40% !important;
  align-items: flex-end;
}


.chartPadding {
  padding-top: 15px;
}

.graphTable {
  border-collapse: collapse;
  text-align: center;
  width: 100%;
  margin-bottom: 13px;
}

.graphTable tr td:first-child {
  border: 0;
  color: #62616E;
  font-size: 12px;
  font-weight: bold;
}
.graphTable .header {
  color: #707070;
  font-weight: bold;
  font-size: 14px;
  border: 0;
}

.graphTable td {
  font-size: 12px;
  border: 1px solid #dedee1;
  padding: 4px;
}

td .won {
  float: left;
  width: 22px;
  height: 10px;
  margin-top: 5px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #ED5230;
}

td .open {
  float: left;
  width: 22px;
  height: 10px;
  margin-top: 5px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #F68E77;
}

td .lost {
  float: left;
  width: 22px;
  height: 10px;
  margin-top: 5px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #4A2B24;
}

td .gwon {
  float: left;
  width: 22px;
  height: 10px;
  margin-top: 5px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #FBB147;
}

.graphTable tr:first-child td
{
  border: none;
}

.col_xs_8 {
  width:70%;
}

.col_xs_12 {
  width: 100%;
}

.mobile_view {
  display: none;
}

.pipelineIcon_pic {
  width: 138px;
}

/*** media query **/

/* ----------- Standard desktop Screens ----------- */
@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) { 
    .leadsDatePicker {
      padding-left: 42px !important;
    }
    
    .rewardsDatePicker {
      padding-left: 14px !important;
    }

    .handoverDatePicker {
      padding-left: 24px;
      padding-right: 0px;
    }

    .crossSalesDatePicker {
      padding-left: 20px !important;
    }

}

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .ahref {
      font-size: 11px !important;
    }
    .leadsDatePicker {
      padding-left: 42px !important;
    }
    
    .rewardsDatePicker {
      padding-left: 14px !important;
    }

    .handoverDatePicker {
      padding-left: 24px;
      padding-right: 0px;
    }

    .handoverRow {
      justify-content: space-between;
      padding-right: 0px;
    }

    .crossSalesDatePicker {
      padding-left: 20px !important;
    } 

    .selectDropdownTitle {
      width: 100%;
    }

    .selectOrgOptions {
      width: 100px !important; 
    }
}

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2) {
  .datePickerRange {
    padding-right: 27%;
    padding-left: 0%;
  }
  // .desktop_view div {
  //   max-width: 100% !important;
  //   margin-bottom: 10px !important;
  //   padding: 0px !important;
  // }
  .col-xs-2 {
    width: 20% !important;
    margin-top: 5px;
  }
  
  .col_xs_8 {
    width: 80% !important;
  }

  .selectOrgOptions {
   // max-width: 100% !important; 
   width: 50%;
   margin-bottom: 5% !important;
  }

  .ahref {
    margin-left: 11px !important;
    font-size: 10px !important;
  }
  
  .handoverDatePicker {
    padding-left: 21%;
    margin-left: -21%;
  }
}
@media(max-width:768px){
  // .content-values{ font-size: 36px !important; }    
  .icon_pic { width: 138px !important;}
  .content_values1 {
    text-align: center !important;
    font-size: 18px !important;
  }
  .title_Pulselite {
    text-align: center;
  }
  .selectDropdownTitle {
    text-align: center !important;
    font-size: 18px !important;
    display: block !important;
    padding-right: 0px !important;
    margin-bottom: 40px;
    //border: 1px solid rgb(204, 204, 204);
  }
  // .mobile_view {
  //   display: block;
  // }
   
  // .desktop_view {
  //   display: none !important;
  // }

  .ahref {
    font-size: 15px;
    :hover{
      color: #3A4860; 
    }
  }

  // .MuiInput-underline:hover:not(.Mui-disabled):before {
  //   border-bottom: none !important;
  // }
   

  // .sales-content-text {font-size: 18px !important; margin-top: 0px;}
  // .item-title{ font-size: 14px !important;}
  }
  @media(max-width:753px){
  // .card-title.community-header{ display: block; text-align: center; height: 100%; margin: 16px 0 16px 0; border-bottom: 0px;}
  // .card-title.community-header .title{ display: block; width: 100%; margin: 0px;}
  }
  @media(max-width:767px){
      // .item-title{
      //     width: 170px;
      //     white-space: nowrap;
      //     text-overflow: ellipsis;
      //     overflow: hidden;
      // }
  }

   /* For general iPad layouts */
  @media only screen and (device-width: 768px) {
    .icon_pic { width: 100px !important;}
  }

  .calendar_container {
    padding-top: 8px;
  }

  