.newsTitle{
    font-size:22px !important;
    text-align: center!important;
    font-weight: 600;
    margin-top: 0px !important;
}

.closeBtn{
    top: -10px;
    float: right;   
    padding: 12px 12px 7px 12px !important;
    right: 8px;
}

.dialogContents{
    margin: 30px
}
  
.uploadLabel{
    margin-left: 15px;
    color: #232f44;
}

label {
    margin-bottom: 10px !important;
  }
  
label.labelFileWrapper {
    margin-bottom: 0px !important;
}

.expiryDateCheckBox {
    padding-top: 7%;
}
.bottomBar {
    padding-top: 20px;
    
}
.inputFileWrapper{
    max-width: 100%;
    width: 100%;
    background: transparent;
    
    margin-left: 15px;
}
.labelFileWrapper{
    border: 1px solid #c0c0c0;
    border-radius: 5px;
    width: 100%;
    display: table;
    table-layout: fixed;
    /* height: 53px; */
    height: 46px;
    background: transparent;
    transform: translate(0%, 0%);
    opacity: 1;
}

.imageHelperText{
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #9c9bb2;
    font-size: 10px !important;
    margin: 7px 0px 0px 0px !important;
    line-height: 14px !important;

}
.labelFileWrapper:hover{
    background: transparent

}

.errorBorder{
}

.imgSpanSelect{
    position: fixed;
    white-space: nowrap;
    width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #9e9898;
    display: table-cell;
    vertical-align: middle;
    word-break:break-all;
    left: 15px;
    top: 14px;
}



.browseFileButton{
    background-color:#d3d3d3;
    height: 100%;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    width: 88px;
    border-radius: 5px;
    /* opacity: 0.5; */
    opacity: 10;
       
    }
    
    #title-helper-text{
        margin: 8px 0px 0px 0px !important;
    }
    .errorMessage{
        /* margin: 7px 0px 0px 0px !important; */
        /* margin: 2px 0px 0px 0px !important; */

        font-size: 12px !important;

        color:#cc0000 !important;
    }

    .errorMessageText{ 
        margin: 2px !important;

        font-size: 12px !important;

        color:#cc0000 !important;

    }
    .newsDescriptionContainer{
        margin: 10px 0px 10px 0px !important;
        color: #232f44;
    }
    



    .btnAll{
        position :absolute;
        float:right;
        text-transform: none !important;
        padding: 1.6% 4% !important;
        margin:1% !important;
        margin: 5px 0px 5px 0px !important
    }


    input[type="file"]
    {
      display: none;
    }



    /* .MuiDialog-paperWidthSm-82{
        max-width: 910px !important;
    } */
    
    


    .previewBtn{
        background-color:#232f44 !important;
        color: white !important;
        margin-right: 15px !important;
        text-transform: none !important;
        border: 1px solid #1b2434 !important;
    
    
    }
    
    .saveBtn{
         background-color:#232f44 !important;
        color: white !important;
        margin-right: 15px !important;
        text-transform: none !important;
    
    
    }
    .publishBtn{
        /* background-color: #d5d5d5 !important; */
        background-color: #e0e0e0 !important;
        color: black !important;
        margin-right: 15px !important;
        text-transform: none !important;
    
    }

    .publishBtn:hover{
        background-color:#19d98c !important;
        color: white !important;
    }

    .publishBtn:hover{
        background-color:#19d98c !important;
        color: white !important;
        margin-right: 15px !important;
        text-transform: none !important;
    }

    .resetBtn{
    
        text-transform: none !important;
    }
    
    .inputField{
        padding: 0px 10px 0px 10px;
        width: 96%;
        /* height: 53px; */
        height: 46px;
        border-radius: 5px;
        border: 1px solid #c0c0c0;
        text-align: justify;

    }
    
    .inputField:focus{
        border-radius: 5px;
    }
    .discriptionTextArea{
        border-radius: 5px;
        border: 1px solid #c0c0c0;
        width: 100%;
        text-align: justify;
        min-height: 80px;
        max-height: 80px;
        height: 80px;
        padding: 10px 10px 10px 10px;

    }
    .discriptionTextArea:focus{
        outline: none !important;
        border-radius: 5px;
    }
    
    .SalesNewsPopup > div > div{
        width: 751px;
    }
    
    @media (max-width: 480px) {
        .inputFileWrapper{
            max-width: 100%;
            width: 100%;
            margin: 0px !important;
            background: transparent;
        }
        .inputField{
            padding: 0px 10px 0px 10px;
            width: 100%;
            height: 53px;
            border-radius: 5px;
            border: 1px solid #c0c0c0;
            text-align: justify;
        }
        .uploadLabel{
            margin-left: 0px;
        }
        .saveBtn{
            background-color:#232f44 !important;
            color: white !important;
            text-transform: none !important;
            margin: 0px !important;
        }
        .btnAll{
            margin: 0px !important;
            padding: 0px;
        }
        .imgSpanSelect{
            width: 100px;
        }
    }
    @media (max-width: 768px) {
        .inputField{width: 100%;}
        .inputFileWrapper{margin-left: 0;}

    }

    @media (max-width: 767px) {
        .inputFileWrapper{
            max-width: 100%;
            width: 100%;
            margin: 0px !important;
            background: transparent;
        }
        .inputField{
            padding: 0px 10px 0px 10px;
            width: 100%;
            height: 53px;
            border-radius: 5px;
            border: 1px solid #c0c0c0;
            text-align: justify;
        }
        .uploadLabel{
            margin-left: 0px;
        } 
        .saveBtn{
            background-color:#232f44 !important;
            color: white !important;
            text-transform: none !important;
            margin: 0px !important;
       }
       .btnAll{
            margin: 5px 0px 5px 10px !important;
            padding: 0px;
        }
        .imgSpanSelect{
            width: 100px;
        }




    }

    @media (max-width: 480px) {
    .dialogContents{
        margin: 30px 16px;
    }
}
    
    .newTitleLabelWrap{
        margin-right: 15px;
        color: #232f44;
    }
    .requiredSpan{
        color:red;
    }
    .charTitle{
        margin-top: 3px !important;
        font-size: 12px !important;
        float: right;
        color: rgba(0, 0, 0, 0.54);
    }

    .pstatic{
        /* position: initial !important; */
      }

    .psticky{
        position: sticky;
    }

    .inputStyle {
        height: 46px;
        border: solid 1px #c0c0c0;
        /* // margin-bottom: 8px; */
        margin-bottom: 0px;
        /* margin-top: 5px !important; */
        width: 100%;
        padding: 5px;
        border-radius: 3px;
      }

    .date_picker_icon {
        background: url(../../../assets/images/content-images/calender.png) 0 0
          no-repeat;
        width: 22px;
        height: 21px;
        display: inline-block;
        position: absolute;
        /* // right: 15px; */
        /* // top: 15px; */
        right: 0px;
        top: 80px;
        /* //cursor: pointer !important; */
        pointer-events: none;
      }

      .SalesNewsDialog_expiryDateCheckBox__unLmx {
          padding-top: 0px !important;
      }
      
      .dropdown-content {
          max-height: 150px !important;
      }