.login_page {
    width: 100%;
    min-height: 100vh;
    background-image: url(../../../../assets/bg/reset_pwd.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: 'Open Sans', sans-serif;
    display: flex;
    justify-content: center;
}

.login {
    width: 400px;
    margin: auto;
    box-sizing: border-box;
    text-align: center;
    vertical-align: middle;
    color: #ffffff;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000029;
    opacity: 1;
    height: auto;

    .logo_container {
        padding: 16px 30px 16px 30px;
        width: 100%;
        box-sizing: border-box;

        .logo {
            width: 10rem;
            height: auto;
        }
    }

    .welcome {
        width: 100%;
    
        .heading {
            font-size: 25px;
            font-weight: 300;
            display: inline-block;
            color: #232F44;
        }
    
        .heading_leadx {
          font-size: 36px;
          font-weight: 300;
          margin: 0 0 10px 0;
          font-weight: bold;
          display: inline-block;
          margin-left: 10px;
          color: #232F44;
        }
    
        h5 {
          color: #232F44;
          font-size: 12px;
          text-align: center;
          margin-bottom: 0px;
        }
      }
    

    .login_form {
        width: 100%;
        padding: 13px 37px 0px 37px;

        .input_container {
            margin-bottom: 15px;
            width: 100%;
            max-width: 100%;
            
            p {
                margin: 0;
            }
        }

        .input {
            height: 45px;
            width: 100%;
            padding: 20px 29px;
            margin: auto;
            color: #48484F !important;
            border: 1px solid #BDBEC0;
            border-radius: 8px;
            font-size: 16px !important;
            font-style: italic;
            caret-color: #ffffff;
            input:-webkit-autofill {
                background-color: #ffffff !important;
                font-style: italic;
            }
        }

        .input::after {
            border-bottom: none !important;
        }
        .input::before {
            display: none;
        }

        ::placeholder {
            color: #a1abbb;
            opacity: 1;
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #a1abbb;
        }

        .login_button_container {
            display: flex;
            justify-content: center;
            height: 50px;

            .button {
                height: 100%;
                width: 50%;
                background-color: #17d98b;
                color: #ffffff;
                padding: 15px 15px;
                cursor: pointer;
                border: none;
                font-size: 16px;

                span {
                    text-transform: none;
                    font-size: 16px !important;
                    line-height: normal;
                }
            }

            .button:disabled {
                background-color: grey;
                cursor: not-allowed;
            }
        }
    }

    .forgot_password {
        margin-bottom: 30px;

        h5 {
            font-size: 16px;

            a {
                color: #ffffff !important;
                text-decoration-line: underline;
            }

            a:hover,
            a:active {
                color: #17d98b !important;
            }
        }
    }
}

.errors {
    color: #cc0000;
    display: block;
    text-align: left;
    line-height: 1.2;
}

@media(max-width:767px) {
    .login {
        width: auto;
        max-width: 400px;
    }
}

.hint{
    color: #232F44;
    text-align: justify;
    font-size: 10px !important;
    letter-spacing: 0px;
}