.title_Administration {
  margin: 0px;
  font-size: 1.25em;
  font-weight: bold;
  color: #232f44;
  text-align: left;
}
.saveButton {
  width: 160px;
  height: 46px;
  object-fit: contain;
  border-radius: 3px;
  color: white;
  margin-right: 0px !important;
  margin-top: 15px !important;
  text-transform: capitalize !important;
}
.saveButton:disabled {
  color: #8a919c !important;
  background-color: #f5f5f5 !important;
  border: #eaebf1 !important;
}
#pendingusers td {
  padding: 10px !important;
}
.selectCompany {
  height: 37px;
  margin-top: 2px;
  border: solid 1px #c0c0c0;
  width: 100%;
  background-color: white;
  padding: 5px;
  border-radius: 3px;
  -webkit-appearance: none;
}
.pdf_icon {
  background: url(../../../assets/svgs/pdf.svg) 0 0 no-repeat;
  height: 20px;
  padding-top: 2em;
  margin-top: 0.2em;
  margin-left: 0.2em;
}
.excel_icon {
  background: url(../../../assets/svgs/excel.svg) 0 0 no-repeat;
  width: 20px;
  height: 20px;
  padding-top: 2em;
  margin-top: 0.2em;
  margin-left: 0.4em;
}
.calenderDownArrowIcon {
  margin-left: 5px;
  margin-right: -5px;
  margin-top: 2px;
}
.custom_table{
  th:last-child{
    padding:0px 0px 14px;
  }
};