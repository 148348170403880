.title_Administration {
  margin: 0px;
  font-size: 1.25em;
  font-weight: bold;
  color: #232f44;
  text-align: left;
}

.title_text {
  margin-top: 1.25em;
  font-weight: 650;
}

.request_title {
  display: flex;
  align-items: center;
}

.search_box {
  text-align: right;
}

.title_right_text {
  margin-top: 1.875em;
  font-weight: 650;
  font-size: 1rem !important;
}

@media(max-width:768px){
  .search_box {
    text-align: center;
  }
}

#pendingusers td{
  padding: 10px !important;
}
 
.custombadge{
  background: #F15E4D;
  color: #ffffff;
  width: 22px;
  height:22px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center; 
  align-self: center; 
  margin-left: 0.5em;
};

.custom_table{
  th:last-child{
    padding:0px 0px 14px;
  }
};

button.header_btn{
  font-size: 14px !important;
  text-transform: none !important;
  height: 40px;
  white-space: nowrap;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #3a4860;  
  margin-bottom: 10px;
  //width: 27%;
  color: #FFFFFF !important;
  border-color: #3a4860 !important;
  background-color: #3A4860 !important;
}

button.header_btn:focus {
  border-color: #3a4860 !important;
}

.header_btn:focus, .header_btn:hover {
  border-color: #3a4860 !important;
  box-shadow: none !important;
  background-color:#19D98B !important;
}
.btn-primary:focus, .btn-primary.focus {
  border-color: #3a4860 !important;
  border:none;
  box-shadow: none !important;
}
