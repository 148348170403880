.title_Administration {
  margin: 0px;
  font-size: 1.25em;
  font-weight: bold;
  color: #232f44;
  text-align: left;
}

.org_profile_logo {
  margin-top: 2.145em;
  width: 12.5em;
}

.card_view {
  margin-top: 0.625em;
}

.bottom_update_button {
  text-align: right;
  margin-top: 4.375em;
  margin-bottom: 4.375em;
}

.title_text {
  margin-top: 1.25em;
  font-weight: 650;
}

.title_right_text {
  margin-top: 1.875em;
  font-weight: 650;
  font-size: 1rem !important;
}

.Select {
  width: 27.75em !important;
  margin-right: 0.3125em;
  z-index: 0 !important;
}

.Select.is-open {
z-index: 1 !important;
}

.Select-menu {
  position: fixed !important;
  width: 27.5em !important;
  margin: 0.0625em;
}

.inputStyle {
  height: 37px;
  border: solid 1px #c0c0c0;
  // margin-bottom: 8px;
  margin-bottom: 0px;
  margin-top: 5px !important;
  width: 100%;
  padding: 5px;
  border-radius: 3px;
}

.inputStyleOrg {
  width: 60%;
}

.selectCompany {
  height: 46px;
  margin-top: 5px !important;
  border: solid 1px #c0c0c0;
  width: 100%;
  background-color: white;
  padding: 5px;
  border-radius: 3px;
  -webkit-appearance: none;
  background: url(../../../assets/images/content-images/select-arrow.png);
}

.containerCustom {
  position: relative;
  display: inline-block;

  .middle {
    position: absolute;
    top: 60%;
    left: 85%
  }
}

.image {
  height: auto;
}

.rounded{
  left: 40%;
  position: absolute;
  top: 63%;
  background: #3a4860;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center; 
  text-align: center;
  cursor: pointer;   
};

.errorMessage {
  font-size: 12px !important;
  color: #cc0000;
}

.url_container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 12px;
}

.cursor_pointer {
  cursor: pointer;
  font-weight: 650;

}