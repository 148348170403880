.cust_dropdown{
    position: relative;
    box-sizing: border-box;
    outline: none;
  }
  .cust_dropdown_heading_open{
    box-sizing: border-box;
      background-color: rgb(255, 255, 255);
      border-color: rgb(217, 217, 217) rgb(204, 204, 204) rgb(179, 179, 179);
      border-radius: 4px 4px 0px 0px;
      border-width: 1px;
      border-style: solid;
      border-image: initial;
      color: rgb(51, 51, 51);
      cursor: pointer;
      display: table;
      border-spacing: 0px;
      border-collapse: separate;
      height: 36px;
      outline: none;
      overflow: hidden;
      position: relative;
      width: 100%;
  }
  
  .cust_dropdown_heading{
    box-sizing: border-box;
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(204, 204, 204);
      border-radius: 4px;
      color: rgb(51, 51, 51);
      cursor: pointer;
      display: table;
      border-spacing: 0px;
      border-collapse: separate;
      height: 36px;
      outline: none;
      overflow: hidden;
      position: relative;
      width: 100%;
  }
  .cust_dropdown_heading_value{
    box-sizing: border-box;
    bottom: 0px;
    color: rgb(51, 51, 51);
    left: 0px;
    line-height: 34px;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    right: 0px;
    top: 0px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .cust_dropdown_heading_dropdown_arrow{
    box-sizing: border-box;
    cursor: pointer;
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 25px;
    padding-right: 5px;
  }
  .cust_dropdown_content{
    border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      background-color: rgb(255, 255, 255);
      border-width: 1px;
      border-style: solid;
      border-color: rgb(230, 230, 230) rgb(204, 204, 204) rgb(204, 204, 204);
      border-image: initial;
      box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 0px;
      box-sizing: border-box;
      margin-top: -1px;
      max-height: 300px;
      position: absolute;
      top: 100%;
      width: 100%;
      z-index: 1;
      overflow-y: auto;
  }
  .cust_select_panel{
    box-sizing: border-box;
  }
  .cust_search_box{
    width: 100%;
    box-sizing: border-box;
    padding: 0.5em;
  }
  .cust_search_input{
    display: block;
    max-width: 100%;
    border-radius: 3px;
    box-sizing: border-box;
    height: 30px;
    line-height: 24px;
    border: 1px solid rgb(222, 226, 228);
    padding: 10px;
    width: 100%;
    outline: none;
  }
  .cust_select_list{
    margin: 0px;
    padding-left: 0px;
  }
  .cust_select_li{
    list-style: none;
    
    :hover{
      background-color: rgb(235, 245, 255);
    }
  }
  .cust_select_item{
    background-color: rgb(235, 245, 255);
  }
  .cust_item{
    box-sizing: border-box; 
    display: block; 
    cursor: pointer;
    padding: 5px 0 5px 12px;
    margin: 3px 0 3px 0px;
    margin-bottom: 0px !important;
    // :hover{
    //   box-sizing: border-box; background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); cursor: pointer; display: block; padding: 8px 10px;
    // }
    // :focus{
    //   box-sizing: border-box; background-color: rgb(255, 255, 255); color: rgb(102, 102, 102); cursor: pointer; display: block; padding: 8px 10px;
    // }
  }
  .cust_up_arrow{
    box-sizing: border-box; border-color: transparent transparent rgb(153, 153, 153); border-style: solid; border-width: 0px 5px 5px; display: inline-block; height: 0px; width: 0px; position: relative; top: -2px;
  }
  .cust_dwn_arrow{
    box-sizing: border-box; border-color: rgb(153, 153, 153) transparent transparent; border-style: solid; border-width: 5px 5px 2.5px; display: inline-block; height: 0px; width: 0px; position: relative;
  }

.title_Administration {
    margin: 0px;
    font-size: 1.25em;
    font-weight: bold;
    color: #232f44;
    text-align: left;
}

.selectOrgOptions {
    background-color: hsl(0,0%,100%);
    border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-width: 1px;
    max-width: 250px !important;
    padding: 0px !important;
    margin: 0 !important;
    height: 2.5em ;
}

button.header_btn{
    font-size: 14px;
    text-transform: none;
    height: 47px;
    padding:0 8px;
    white-space: nowrap;
    text-align: center;
    margin-left: 5px;
    margin-right: 5px;
}

button.creat_btn{
    background-color: #3a4860;  
    margin-bottom: 10px;
    width: 27%;
} 

.RenewalText {
    font-size: 1em;
    font-weight: 500;
}

.title_Users {
    // display: flex;
    font-size: 15px !important;
    color: #232f44;
    text-align: left;
}

.usercount_flex {
    display: flex;
    margin-top: 10px;
}
  
.usercount_flex > div {
    width: 25%;
    line-height: 75px;
    font-size: 30px;
}

.icon_pic {
    width: 100px;
}

.usercount_values {
    color: #17d98b;
    font-weight: 500;
    font-size: 20px !important;
}

.flex_item_values {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
  
.flex_item_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.userCount_value {
    color: #17d98b;
    font-weight: 500;
    font-size: 25px !important;
}

.userCount_title {
    margin-top: -44px;
    font-weight: bold;
    color: #232f44;
    text-align: left;
}

.title_right_text {
    margin-top: 1.875em;
    font-weight: 650;
    font-size: 14px !important;
    color: #232F44;
}

.plans_flex {
    display: flex;
    margin-top: 10px;
}

  
.plans_flex > div {
    width: 33.33%;
    line-height: 75px;
    font-size: 30px;
}

.cards {
    display: flex;
    width:auto;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
}

.cards__item {
    padding: 1rem;
    @media(min-width: 40rem) {
      width: 50%;
    }
    @media(min-width: 56rem) {
      width: 33.3333%;
    }
}
  
.card {
    height: 100% !important;
    background-color: white;
    border-radius: 0.25rem;
    box-shadow: 20px 26px 26px -16px rgba(171, 171, 171, 0.25);
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 1px solid rgba(171, 171, 171, 0.25);
    &:hover {
      .card__image {
        filter: contrast(100%);
      }
    }
}
  
.card__content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 1rem;
    line-height: 2.2;
}

.card__title h4{ 
    color: #232F44;
    font-size: 1.25rem;
    font-weight: 300;
    padding: 1rem;
    text-align: left;
    font-weight: 700;
}

.card_title_text {
    font-weight: 700;
    color: #232F44;
    font-size: 14px !important;
}

.hr{
    width: 90%;
}

.card_truncation{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    :hover.popup {
        visibility: visible;
        padding: 12px 12px;
        height: 84%;
        overflow-y: scroll;
        opacity: 1;
        border: 1px solid #eeeeee;
    }
}

.popup{
    visibility: hidden;
    position: absolute;
    top: 32px;
    background: white;
    border-radius: 6px;
    padding: 0 12px;
    -webkit-transition: all 500ms;
    transition: all 500ms;
    z-index: 100;
    padding: 12px 12px;
    height: 84%;
    overflow-y: scroll;
    opacity: 1;
    border: 1px solid #eeeeee;
    ul {
        padding-left: 20px;
    }
    li {
        list-style: circle;
    }
    
}
.card_content_inner {
    font-size: 11px !important;
    color: #232F44;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: inline-block;
    // p {
    //     overflow: hidden;
    //     text-overflow: ellipsis;
    //     display: -webkit-box;
    //     -webkit-line-clamp: 2;
    //     -webkit-box-orient: vertical;
    // }
    
    // :hover {
    //     visibility: visible;
    //     width: 120px;
    //     background-color: black;
    //     color: #fff;
    //     text-align: center;
    //     border-radius: 6px;
    //     padding: 5px 0;
    //     position: absolute;
    //     z-index: 1;
    // }

    ul {
        padding: 0px;
        list-style: none;
        li {
            font-size: 11px !important;
        }
    }
    // p {
    //     overflow: hidden;
    //     text-overflow: ellipsis;
    //     display: -webkit-box;
    //     -webkit-line-clamp: 1;
    //     -webkit-box-orient: vertical;
    // }
    
}

.card_button
{
    background-color: #9CA3AF;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    width: 70px;
    float: right;
    a, a:hover {
        color: #ffffff !important;
        text-decoration: none
    }
}


.card::before,
.card::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-color: transparent;
    border-style: solid;
}

.card::before {
    border-width: 1.5em;
    border-right-color: #ccc;
    border-top-color: #ccc;
}

.card::after {
    border-radius: 0.4em;
    border-width: 2.35em;
    border-right-color: #1ACA85;
    border-top-color: #1ACA85;
}

/*Card Addons*/
.card_addons {
    background-color: white;
    border-radius: 0.25rem;
    box-shadow: 20px 26px 26px -16px rgba(171, 171, 171, 0.25);
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 1px solid rgba(171, 171, 171, 0.25);
}
  
.card_addons__content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 1rem;
    line-height: 2.2;
}

.card_addons__title h4{ 
    color: #232F44;
    font-size: 1.25rem;
    font-weight: 300;
    padding: 1rem;
    text-align: center;
    font-weight: 700;
    margin-right: 32px;
}

.card_addons_title_text {
    font-weight: 700;
    color: #232F44;
    font-size: 14px !important;
}

.card_addons_content_inner {
    font-size: 11px !important;
    color: #232F44;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    ul {
        padding: 0px;
        list-style: none;
        li {
            font-size: 11px !important;
        }
    }
    
}

.card_addons_button
{
    background-color: #3A4860;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    width: 70px;
    float: right;
    a, a:hover {
        color: #ffffff !important;
        text-decoration: none
    }
}

.card_addons::before,
.card_addons::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-color: transparent;
    border-style: solid;
}

.card_addons::before {
    border-width: 1.5em;
    border-right-color: #ccc;
    border-top-color: #ccc;
}

.card_addons::after {
    border-radius: 0.4em;
    border-width: 2.35em;
    border-right-color: #1ACA85;
    border-top-color: #1ACA85;
}

.addons_title {
    margin-top: 1.875em;
    font-weight: 650;
    font-size: 14px !important;
    color: #232F44;
    white-space: pre-wrap;
}

.addons_note1 {
    margin-top: 1.875em;
    font-weight: 650;
    font-size: 14px !important;
    color: #F15E4D;
    white-space: pre-wrap;
}

.addons_note2 {
    margin-top: 1.875em;
    font-weight: 650;
    font-size: 14px !important;
    color: #232F44;
    white-space: pre-wrap;
}

.checkmark {
    display: inline-block;
    width: 22px;
    height: 22px;
    transform: rotate(45deg);
    float: right;
    position: relative;
    margin-top: -46px;
    z-index: 1;
}

.checkmark_stem {
    position: absolute;
    width: 3px;
    height: 24px;
    background-color: #fff;
    left: 1px;
    top: -4px;
}

.checkmark_kick {
    position: absolute;
    width: 13px;
    height: 3px;
    background-color: #fff;
    left: -9px;
    top: 19px;
}

.warning {
    margin: -79px 5px;
    float: right;
    width: 24px;
    z-index: 1;
    position: relative;
}
 
.headerBtn {
    text-align: end;
}

.headerBtn Button a {
    color: #fff !important;
    text-decoration: none;
}

.userCount_value span {
    font-size: 25px !important;
}
.popoverClass{
    background-color: #9f9f9f !important;
    box-shadow: none !important;
    color: #fff!important;
    font-size: 5px !important;
    padding: 3px !important;
    text-transform: lowercase !important;
  }

@media only screen and (max-width: 768px) {
    .icon_pic {
        width: 60px;
    }
    .userCount_value {
        color: #17d98b;
        font-weight: 500;
        font-size: 25px !important;
    }
  }

  .warnIconRightCorner{
    position: absolute;
    left: 90%;
    margin-top: 5px;
    margin-left: 2px;
    z-index: 1;
    img{
        width: 23px;
    }
  }