.profile_popover > h3{
    padding: 15px !important;
}
.profile_popover{
    color: #232f44 !important;
}
.profile_popover > div{
   background: #3a4860 !important;
   color: #ffffff;
   cursor: pointer;
}

div.profile_popover{
    // position: fixed;
    // z-index: 99;  
    // top: -9px !important;
    // left: -244px;
    width: 480px;
    max-width: 500px;
    height: auto;
    border-radius: 0px;
    border: 0px;
    z-index: 99;  
}




.user_name{
    font-size: 24px !important;
    font-weight: 700;
    padding: 0% 0% 2% 0%;
}
.name_section, .email_section{
    border-bottom: 1px solid #e7e7e7;
    padding: 15px 0px;
}
.email_section{
    font-size: 15px !important;
}
.contact_section{
    padding: 15px 0px;
    font-size: 15px !important;
}
.image_section{
    border: 3px solid #19d98c;
    padding: 1%;
    border-radius: 8px;
    width: 100px;
    margin: 20px 0px 0px 15px;
    height: 100px;
}
.image{
    width: 100%;
    height: 100%;
}
.points_section{
    width: 70%;
    background: #19d98c;
    padding: 6% 0%;
    margin: 20px 20px 17px 20px;
    border-radius: 5px;
    text-align: center;
    color: #ffffff;
}
.email_icon, .phone_icon{
    margin-right: 5%;
    font-size: 16px;
}
.email_icon{
    padding-bottom: 2%;
}
.view_profile{
    text-align: center;
    padding: 0px 0px 20px 0px;
    font-size: 15px !important;
}
a, a:hover{
    color: #19d98c !important;
}
.role_label{
    font-size: 15px !important;
}
.pr_30{
    padding-right: 30px !important;
}

.profileEmailId{
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    padding-bottom: 1px;  
}

/*** media query ***/
@media only screen and (max-width: 1336px) {
div.profile_popover{left: -283px;}   
}
@media only screen and (max-width: 1280px) {
    div.profile_popover{left: -296px;}   
}
@media only screen and (max-width: 1024px) {
    div.profile_popover{left: -321px;}   
}
@media only screen and (max-width: 768px) {
    div.profile_popover{    left: -363px;    }   
}
/*** media query ***/