.login_page {
    width: 100%;
    min-height: 100vh;
    background-image: url(https://s3.eu-north-1.amazonaws.com/live-media.leadx.no/Leadx-staging/common/images/login_background_web.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: 'Open Sans', sans-serif;
    display: flex;
    justify-content: center;
}