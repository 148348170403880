.container {
    width: 100%;
    min-height: 100vh;
    background-image: url(../../../assets/bg/register.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: 'Open Sans', sans-serif;
    display: flex;
    justify-content: center;
}