.dialogContent {
  overflow-x: unset !important;
  overflow-y: unset !important;
}

.container_body {
  // margin-top: 20px;
  margin-top: 25px;
  padding: 0 15px;
}
.update_btn_margin_space {
  margin-bottom: 25px;
}
.activities_container {
  width: 100%;
  border-radius: 5px;
  background-color: #ffffff;
  color: #1c2535;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 30px;

  hr {
    margin: 0;
  }
}

.activities_container_header {
  width: 100%;
  height: 54px;
  background-color: #232f44;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.activities_container_heading {
  font-size: 16px;
  color: #ffffff;
  margin: 0;
}
.activities_container_actions {
  display: flex;
  justify-content: center;
  position: relative;
}
.activities_container_body {
  overflow-y: scroll;
  max-height: 242px;
  width: 100%;
}

.activities {
  width: 100%;
  padding: 10px;
}

.meeting,
.email,
.phone,
.chat {
  width: 100%;
  display: flex;
  border-radius: 5px;
  height: 56px;
  align-items: center;
  margin-bottom: 10px;
}

.tasks {
  padding: 10px 20px;
  width: 100%;
  border-radius: 5px;
  align-items: center;
  // margin-bottom: 10px;
  background-color: #eeeff0;
  border-left: 5px solid #232f44;
  border-bottom: 1px solid #dedfe4;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.meeting {
  background-color: #fbe9ec;
  border-left: 5px solid #e1667f;
}

.email {
  background-color: #e5f0fe;
  border-left: 5px solid #4c97f7;
}

.phone {
  background-color: #fbf4d9;
  border-left: 5px solid #ffd941;
}

.chat {
  background-color: salmon;
  border-left: 5px solid red;
}

.timing {
  margin: 0 20px;
  color: #232f44;
  font-size: 16px;
  font-weight: 600;
}

.sales_activity {
  width: 100%;
  padding: 10px;
}

.sales_activity_header {
  color: #232f44;
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 4px 0;
}

.contact_detail {
  .contact {
    display: inline-block;
    width: 50%;
  }

  img {
    width: 14px;
    height: 14px;
    margin-right: 9px;
  }

  h6 {
    // display: inline;
    // font-size: 12px;
    // color: #1c2535;
    display: block;
    font-size: 12px;
    color: #1c2535;
    width: 70px;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
  }
}

.date_container {
  padding: 10px;

  .date {
    margin: 0;
    font-size: 14px;
    color: #232f44;
  }
}
.stage_name {
  color: #9c9bb2;
}
.stage_label,
.stage_name {
  font-size: 14px !important;
}
.handshakeIconSetion {
  display: inline-block;
  float: right;
  // margin: 1% 4% 0% 0%;
  margin: 2% 4% 2% 0%;
}
.lead_dialog {
  display: block;
}

.listContent {
  margin-top: 6% !important;
}

.listTitle {
  font-weight: bold !important;
  color: #232F44;
  font-size: 20px !important;
  letter-spacing: 0px !important;
  margin-bottom: 0px;
}

.listBottom {
  width: 100%;
  margin-bottom: 0px;
  border: 1px solid #DADADA;
}

.listText {
  font-size: 14px !important;
  color: #3A4860;
}

.confirmText {
  font-size: 18px !important;
  font-weight: bold !important;
  color: #232F44;
  letter-spacing: 0px !important;
  margin-bottom: 0px;
}

.cancleBtn {
  color: #3A4860 !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
  letter-spacing: 0px !important;
}

.deleteBtn {
  background: #3A4860 !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
}

.dialogAct {
  padding: 24px !important;
}

.deleteLead {
  float: right;
  cursor: pointer;
  img {
    width: 18px;
  }
  span {
    font-size: 12px !important;
    color: #3A4860;
    padding: 6px;
    letter-spacing: 0px;
    font-weight: bold;
  }
}

.popup_row {
  border-bottom: 1px solid #eaebf1;
  margin-left: -30px !important;
  margin-right: -30px !important;
}
.dialogBtn {
  padding: 25px 10px 5px 20px;
  justify-content: center;
}
.textCenter {
  text-align: center !important;
}
.ringBtn {
  background: url("../../../../assets/images/content-images/ring_the_bell_background.png")
    no-repeat;
  width: 230px !important;
  font-size: 14px !important;
}
.bellIcon {
  position: absolute;
  top: 3px;
  margin-left: 2%;
}

.avataar {
  width: 60px !important;
  height: 60px !important;
  margin-left: 0px;
  margin-right: 10px;
  background-color: #232f44;
}

.icon {
  img {
    width: 47%;
    height: 50%;
  }
}

.form_title {
  font-size: 14px !important;
  margin-bottom: 0px !important;
  color: #232f44 !important;
  margin-top: 10px;
  padding-left: 14px;
}

.uploaded_items {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 15px;
}

.linkedin-hover {
  font-size: 14px !important;
  color: #9c9bb2;
  margin-left: 5px;
}

a:hover {
  //text-decoration: none !important;
  color: #9c9bb2 !important;
}

/******/
@media (max-width: 768px) {
  .pipeline-popup-grid {
    padding: 8px 30px !important;
  }
}
@media (max-width: 769px) {
  .container_body {
    padding: 0 0px;
  }
}
@media (max-width: 480px) {
  .handshakeIconSetion {
    display: block;
    float: none;
  }
  .uploaded_items {
    display: block;
  }
  .uploaded_items_box{
    display: block;
    float: left;
    margin-bottom: 10px;
  }
}




@media (max-width: 768px) {
  .pipeline-popup-document {
    padding: 8px 30px !important;
  }
}
