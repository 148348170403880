.register {
  margin: auto;
  box-sizing: border-box;
  text-align: center;
  vertical-align: middle;
  color: #ffffff;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #00000029;
  opacity: 1;
  height: auto;

  .logo_container {
    padding: 16px 30px 16px 30px;
    width: 100%;
    box-sizing: border-box;

    .logo {
      width: 10rem;
      height: auto;
    }
  }

  .welcome {
    width: 100%;

    .heading {
      font-size: 20px;
      font-weight: 300;
      display: inline-block;
      color: #232F44;
      font-weight: bold;
    }

    .heading_leadx {
      font-size: 36px;
      font-weight: 300;
      margin: 0 0 10px 0;
      font-weight: bold;
      display: inline-block;
      margin-left: 10px;
      color: #232F44;
    }

    h5 {
      color: #232F44;
      font-size: 12px;
      text-align: center;
      margin-bottom: 0px;
    }
  }


  .MuiFormGroup-root-9 {
    flex-direction: row !important;
  }

  .register_form {
    width: 100%;
    padding: 13px 37px 0px 37px;

    .gender_label {
      font-size: 15px !important;
      color: gray;
    }

    .radio_container {
      margin-right: 27px;
      cursor: pointer;
      text-align: left;
      .radio_button {
        flex-direction: row;
        width: 14px;
        cursor: pointer;
        height: 14px;
      }
      .radioControlLabel {
        margin-left: 10px;
        font-size: 15px;
        font-weight: 500;
        cursor: pointer;
        color: #48484F !important;
      }
    }

    .input_container {
      margin-bottom: 15px;
      width: 100%;
      max-width: 100%;
    }

    .input_padding {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    .col-8 {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  

    .genderTitle {
      text-align: left;
      font-size: 16px !important;
      padding-top: 15px;
      color: #9c9bb2 !important;
    }

    .phone_input {
      height: 45px;
      width: 100%;
      padding: 20px 29px;
      margin: auto;
      color: #48484F !important;
      border: 1px solid #BDBEC0;
      border-radius: 8px;
      font-size: 16px !important;
      font-style: italic;
      caret-color: #48484F;
      input:-webkit-autofill {
        background-color: #ffffff !important;
        font-style: italic;
      }
    }

    .select_box {
      height: 45px;
      width: 100%;
      cursor: pointer;
      padding: 1px 15px;
      margin: auto;
      background-color: #ffffff !important;
      font-style: italic;
      color: #48484F !important;
      border: 1px solid #BDBEC0;
      font-size: 16px !important;
      caret-color: #4d5b73;
      border-radius: 8px;
    }
    .select_box :focus{
      border: solid #fff 1px !important;
    }
    .select_box_initials {
        height: 45px;
        width: 100%;
        padding: 17px 15px;
        margin: auto;
        background-color: #ffffff !important;
        color: #48484F !important;
        border: 1px solid #BDBEC0;;
        font-size: 16px !important;
        caret-color: #4d5b73;
        border-radius: 8px;
      }

    select:required:invalid {
      color: gray;
    }
    option[value=""][selected] {
        color: gray;
    }
    option[value=""][disabled] {
      display: none;
    }

    .input {
      height: 45px;
      width: 100%;
      padding: 20px 29px;
      margin: auto;
      color: #48484F !important;
      font-style: italic;
      border: 1px solid #BDBEC0;
      border-radius: 8px;
      font-size: 16px !important;
      caret-color: #48484F;
      input:-webkit-autofill {
        background-color: #ffffff !important;
        font-style: italic;
      }
    }
    .input::after {
      border-bottom: none;
    }

    .phone_input::after {
      border-bottom: none;
    }

    ::placeholder {
      color: #a1abbb;
      opacity: 1;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #a1abbb;
    }

    .register_button_container {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      cursor: pointer;

      .button {
        height: 100%;
        width: 200px;
        background-color: #55BD84;
        color: #ffffff;
        padding: 13px 34px;
        cursor: pointer;
        border: none;
        font-size: 16px;

        span {
          text-transform: none;
          font-size: 16px !important;
          line-height: normal;
        }
      }

      .button:disabled {
        background-color: grey;
        cursor: not-allowed;
      }
    }
  }
}

.MuiFormControlLabel-label {
  color: blue !important;
}

.separator {
  margin: 0px 10px 0px 10px;
  color: gray;
}

.errors {
  color: #cc0000;
  display: block;
  text-align: left;
}

.label {
  font-size: 14px !important;
  color: #232f44;
  margin-bottom: 0px !important;
}

.selectCompany {
  height: 46px;
  margin-top: 5px !important;
  border: solid 1px #c0c0c0;
  width: 100%;
  background-color: white !important;
  padding: 5px;
  border-radius: 3px;
  -webkit-appearance: none;
  background: url(../../../../assets/images/content-images/select-arrow.png) 95%
    center no-repeat;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("../../../../assets/svgs/chevron-right.svg");
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 22px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
  margin-top: 2rem;
}

// Password reset and request accepted handling

.registration_title {
  font-weight: 600;
  padding-bottom: 20px;
  color: #232f44
}

.registration_image {
  //width: 100%;
}

.registration_message {
  padding: 10px 20px 20px 20px;
  color: #232f44;
}

.back_login {
  margin-bottom: 30px;
  color: #232f44;

  h5 {
      font-size: 16px;

      a {
          color: #17d98b  !important;
          text-decoration-line: underline;
      }

      a:hover,
      a:active {
          color: #17d98b !important;
      }
  }
}

@media (max-width: 767px) {
  .register {
    width: auto;
    max-width: 400px;
  }
}

@media only screen and (max-width: 390px) {
  .register {
    max-width: 94%;
  }
}