.form_group {
  margin-bottom: 10px;
}
.errorMessage {
  font-size: 12px;
  color: red;
}
.lead_dialog_title {
  font-size: 22px !important;
  font-weight: 600;
  text-align: center;
  // margin:5px 0 4px 0px;
  margin: 5px 0px;
}
.lead_dialog_content {
  padding: 25px 30px 20px 30px !important;
  overflow-x: hidden;
  //  overflow-y: hidden !important;
}

.form_title {
  font-size: 14px !important;
  margin-bottom: 0px !important;
  color: #232f44 !important;
}

.text_box {
  margin-top: 5px !important;
  width: 100%;
}
.inputStyle {
  height: 46px;
  border: solid 1px #c0c0c0;
  // margin-bottom: 8px;
  margin-bottom: 0px;
  margin-top: 5px !important;
  width: 100%;
  padding: 5px;
  border-radius: 3px;
}
.label {
  font-family: OpenSans !important;
  font-size: 14px !important;
  text-align: left;
  color: #232f44;
}
.taxtArea {
  resize: none;
  height: 75% !important;
  width: 100% !important;
  margin-right: 0px !important;
  border: solid 1px #c0c0c0;
  padding: 5px;
  border-radius: 3px;
  margin-top: 5px;
}
.taxtArea:focus {
  outline: none;
}
.saveButton {
  width: 160px;
  height: 46px;
  object-fit: contain;
  border-radius: 3px;
  color: white !important;
  background-color: #0b0e22 !important;
  // margin-top:-11px!important;
  //margin-top:0px!important;
  //margin-right: 18px!important;
  margin-right: 0px !important;
  // margin-bottom:20px !important;
  //margin-bottom:15px !important;
  margin-top: 15px !important;
  text-transform: capitalize !important;
}
.saveButton:disabled {
  color: #8a919c !important;
  background-color: #f5f5f5 !important;
  border: #eaebf1 !important;
}

.selectCompany.currency_field {
  background: url(../../../../assets/images/content-images/select-arrow.png) 85%
    center no-repeat;
}
.selectCompany {
  height: 46px;
  margin-top: 5px !important;
  border: solid 1px #c0c0c0;
  width: 100%;
  background-color: white;
  padding: 5px;
  border-radius: 3px;
  -webkit-appearance: none;
  background: url(../../../../assets/images/content-images/select-arrow.png) 99%
    center no-repeat;
}
.datepicker_select {
  background: none;
}

.selectOption {
  font-family: OpenSans;
  font-size: 14px;
  color: gray !important;
}
.removeBorder {
  border-top: 0 !important;
  padding: 10px 0px 10px 10px !important;
}
.dateClass {
  height: 46px;
  margin-bottom: 8px;
  margin-top: 5px !important;
  width: 100%;
  border: solid 2px #c0c0c0 !important;
  padding-top: 14px !important;
  padding-left: 8px !important;
}
.lead_radio {
  border: 2px solid #eaebf1;
  padding: 10px 0px;
  text-align: center;
  margin: 5px 0px;
  cursor: pointer;
  border-radius: 3px;
}
.pt_20 {
  padding-top: 20px;
}
.datepicker:first-child {
  width: 100%;
}
.selectedBox {
  border: 2px solid black !important;
  cursor: pointer;
}
.handshakeBtn {
  width: 100%;
  padding: 6px 0px !important;
  justify-content: left;
}
.handshakeBtn:hover {
  background-color: transparent !important;
}
.handshakeBtn > span {
  justify-content: left;
}
// .handshakeBorder{
//   border: solid 2px #eaebf1;
//   margin-top: 5px;
//   border-radius: 3px;
// }
.border_gradient {
  border-image-slice: 1 !important;
  border-width: 2px;
}
.border_gradient_green {
  border-image: linear-gradient(to left, #eaebf1, #19d98c) !important;
}
.has_error {
  color: #cc0000;
  font-size: 12px;
}
.lead_stepper {
  padding: 0px 0px 10px 0px;
  // border-bottom: 1px solid lightgray;
  // margin-bottom: 20px;
}
.status_name {
  text-align: center;
  font-size: 18px !important;
  font-weight: 600;
  margin-top: 10px;
}
.stepper_btns {
  padding: 20px 0px;
}
.stepper_btn {
  padding: 12px;
  border: none;
  text-align: center;
  border-radius: 5px;
  background-color: #ebeae4 !important;
}
.next_stepper_btn {
  float: right;
  background: #f0f1f5 !important;
  border: 1px solid #eaebf1;
}
.prev_stepper_btn {
  background: #f0f1f5 !important;
  border: 1px solid #eaebf1;
}
input:disabled {
  background-color: #f5f5f5 !important;
  border: 1px solid #c0c0c0;
}
.nextArrow {
  font-size: 18px !important;
  margin-left: 10px;
}
.backArrow {
  font-size: 18px !important;
  margin-right: 10px;
}
.required_field {
  color: red;
}
.circular_progress {
  width: 25px !important;
  height: 25px !important;
  position: absolute;
  right: 10%;
  margin-top: 4%;

  select {
    /* for Firefox */
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;
  }

  /* For IE10 */
  select::-ms-expand {
    display: none;
  }
}
.leadStatusBtns {
  border: 2px solid #ebeae4;
  padding: 3% 0%;
  text-align: center;
  margin-bottom: 30px;
  width: 100%;
  cursor: pointer;
}
.handover_btn {
  padding-left: 0px !important;
  margin-bottom: 5px;
}
.pl_0 {
  padding-left: 0px !important;
}

.yesno_btn {
  display: inline-block;
  width: 42%;
  margin-left: 5%;
  margin-right: 2%;
}
.add_icon_section {
  background: #232f44;
  text-align: center;
  margin-top: 25px;
  cursor: pointer;
  border-radius: 5px;
  height: 47px;
}
.add_company_icon {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.datepicker {
  position: relative;
}
.calendarPicker {
  position: absolute;
}

.disable_add_icon {
  // cursor: not-allowed;
  background-color: #f5f5f5 !important; // #6e6f71 !important;
  pointer-events: none;
  border: 1px solid #eaebf1;
}
.disabled_parkBtn {
  background-color: #f5f5f5 !important; // #ebeae4; // #6e6f71 !important;
  pointer-events: none;
  border: 1px solid #eaebf1;
}
.form_group input::placeholder {
  color: #9c9bb2 !important;
  font-size: 13px !important;
}

.disabled_assignedToBtn {
  background-color: #f5f5f5 !important; // #ebeae4; // #6e6f71 !important;
  pointer-events: none;
  border: 1px solid #eaebf1;
}

.lead_char {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.disabled_yes_no {
  pointer-events: none;
  cursor: not-allowed;
  background-color: #f5f5f5;
  color: #8a919c;
  border-color: #eaebf1 !important;
}

.date_picker_icon {
  background: url(../../../../assets/images/content-images/calender.png) 0 0
    no-repeat;
  width: 22px;
  height: 21px;
  display: inline-block;
  position: absolute;
  // right: 15px;
  // top: 15px;
  right: 35px;
  top: 37px;
  //cursor: pointer !important;
  pointer-events: none;
}

// *************************

.browse_btn {
  display: inline-block;
  width: 194px;
  background-color: #ffffff;
}

.footerHint {
  font-size: 12px !important;
  color: grey;
}

.avataar {
  width: 60px !important;
  height: 60px !important;
  margin-left: 0px;
  margin-right: 3px;
  background-color: #232f44;
}

.icon {
  img {
    width: 47%;
    height: 50%;
  }
}

.uploaded_items {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
}

.basic_handshake {
  display: flex;
  //align-items: center;
  justify-content: center;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.text {
  background-color: #4caf50;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}

.circle_overlap {
  margin: 0px 10px 0px 0px;
  padding: 0px;
  display: block;
  float: left;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #ccc;
  position: relative;
}

.circle_overlap .img_avator {
  margin: 0px;
  padding: 0px;
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
}

.circle_overlap .img_avator_close {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  top: 0px;
  display: none;
  text-align: center;
}

.circle_overlap .img_avator:hover .img_avator_close {
  display: flex;
  border-radius: 50%;
  border: rgba(0, 0, 0, 0.9) solid 0;
  align-items: center;
  justify-content: center;
}

.handover_section {
  background-color: #f5f5f5 !important;
  margin: 0px;
  padding: 15px 30px 15px 30px;
}

.handover_or {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(#c0c0c0, #c0c0c0) no-repeat center/2px 100%;
}
.orbackground{
  background: #F5F5F5;
  }
.note_padding {
  padding-top: 20px;
}


@media (max-width: 767px) {
  .add_icon_section {
    margin-top: 31px;
  }
  .pt_20 {
    padding-top: 0px;
    padding-left: 15px !important;
  }
  .lead_dialog_content {
    padding: 25px 15px 10px 15px !important;
  }
  .selectCompany.currency_field {
    background: url(../../../../assets/images/content-images/select-arrow.png)
      95% center no-repeat;
  }
  .selectCompany {
    background: url(../../../../assets/images/content-images/select-arrow.png)
      95% center no-repeat;
  }
  .yesno_btn {
    width: 45.6%;
    margin-left: 2%;
    margin-right: 2%;
  }
}

.handover_styling {
  font-weight: bold;
}
@media only screen and (max-width: 667px) {
  .input_website {
    width: 89%;
  }
  .lead_dialog_title {
    text-align: left;
    margin: 5px 0 4px 20px;
  }
}
@media (max-width: 600px) {
  .leadStatusBtns {
    border: 2px solid #ebeae4;
    padding: 7% 0%;
    text-align: center;
    margin-bottom: 5%;
    width: 100%;
    cursor: pointer;
  }
}
@media (max-width: 569px) {
  .add_icon_section {
    margin-top: 12px;
    margin-bottom: 0px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .selectCompany {
    margin-top: 5px !important;
  }
  .taxtArea {
    margin-top: 5px !important;
  }
}
@media only screen and (max-width: 390px) {
  .lead_dialog_title {
    font-size: 18px !important;
  }
}
