.listContent {
  margin-top: 6% !important;
}

.listTitle {
  font-weight: bold !important;
  color: #232F44;
  font-size: 20px !important;
  letter-spacing: 0px !important;
  margin-bottom: 0px;
}

.listBottom {
  width: 100%;
  margin-bottom: 0px;
  border: 1px solid #DADADA;
}

.listText {
  font-size: 14px !important;
  color: #3A4860;
}

.confirmText {
  font-size: 18px !important;
  font-weight: bold !important;
  color: #232F44;
  letter-spacing: 0px !important;
  margin-bottom: 0px;
}

.cancleBtn {
  color: #3A4860 !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
  letter-spacing: 0px !important;
}

.deleteBtn {
  background: #3A4860 !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
}

.dialogAct {
  padding: 24px !important;
}