@import '../../../common/variables.scss';

.page-heading{
    font-size: 30px !important;
    font-weight: bold;
}
.page-breadcrumb{
    font-size: 14px !important;
}
.community-card{
    width:95%;
}
.card-title.community-header{
    padding: 0px 0px 0px 20px !important;
    margin-bottom: 0px;
    height: 99px;
    display: flex;
    align-items: center;
    border-bottom:1px solid #eaebf1;
}
.title{
    display: inline-block;
    margin-left: 20px;
    font-size: 28px !important;
    font-weight: 600;
    color: $text-color-black;

    div div svg {
        top: calc(50% - 22px);
    }
}
.logo{
    display: inline-block;
    width: auto;
}
.header-content{
    text-align: center;
    color: $text-color-black;
}
.company-logo{
    display: inline-block;
    height: 40px;
    width: 90px;
}
.company-logo img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.content-values{
    color: #17d98b;
    font-weight: 700;
    font-size: 46px !important;
}
.sales-content-text{
    font-size: 24px !important;
    margin-top:-8px;
    color: $text-color-black !important;
}
.sales-header{
    background-color: $text-color-black;
    color: white;
    margin-bottom: 0px;
    padding: 15px 20px;
    border-radius: 5px 5px 0 0;
}
.mb-space{
    margin-bottom:30px;
}
.item-sub-title{
    color:$text-color-black;
    font-size:14px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}
.item-title{
    color: $text-color-black;
    font-weight: 600;
    font-size: 16px !important;
    white-space: nowrap;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.card-rounded{
    border-radius: 0.35rem !important;
    margin-bottom: 30px !important;
}
.sales-title{
    margin-bottom: 0px;
    font-size: 17px !important;
}
.ongoing-sales-title{
    font-size: 16px !important;
    font-weight: 600;
}
.ongoing-comapny-name{
    margin-top:-5px;
}
.sales-item{
    //padding: 14px 20px 21px 20px !important;
    cursor: pointer;
    border-bottom:1px solid #eaebf1;
    border-top:1px solid #eaebf1;
    height: 95px;
    width: 100%;
    overflow: hidden;
}
.my_pipeline_padding {
    padding: 14px 20px 21px 20px !important;
}
.sales-item .corrected_height {
    // padding: 14px 20px 21px 20px !important;
    padding: 14px 5px 21px 5px !important;
    height: 100%;
}
.sales-item .corrected_height_left_right {
    padding-left: 20px !important;
    padding-right: 20px !important;
}
.sales-item:hover{
    background: #f0f9f1;
}
.sales-item-row{
    padding: 0px 15px;
}

.sales-item-row .sales-item:nth-child(even){
    border-right:1px solid #eaebf1;
}
/* .MuiLinearProgress-barColorPrimary-196, .MuiLinearProgress-barColorPrimary-204, .MuiLinearProgress-barColorPrimary-261{ */
.item-progress > div > div{
    // background-color: #17d98b !important;
    border-radius: 4px;
    height: 6px;
}
.item-progress > div{
    background-color: #dff2e6 !important;
    border-radius: 4px;
    height: 6px;
}
.newLinearPrgress{
    background-color: $text-color-black !important;
    border-radius: 4px;
}
.display-inline{
    display: inline-block;
}
.item{
    width: 100%;
}
.item-img{
    vertical-align: baseline;
    width: 36px;
    height: 36px;
    padding-top:2px;
}
.item-desc{
    margin-left: 20px;
}
.item-progress{
    margin-top: 5px;
    height: 7px;
}
.item-status{
    color: #a7abb4;
    font-size: 12px !important;
    padding-right: 30px;
}
.item-status-width {
    width: 110px;
    text-align: right;
}
.item-icon{
    margin-left: 77%;
    color: #8a8686;
    font-size: 20px !important;
    margin-bottom: 10%;
}
.card-image{
    background-image: url('../../../../assets/images/content-images/sales-community-background.png');
    width: auto;
    height: auto;
    object-fit: contain;
    border-radius: 5px;
    padding: 10px 20px 5px 10px !important;
}
.sales_community_cardBody{
    padding: 0px !important;
}
.sales-view-all{
    padding: 14px 20px;
    background-color: #f0f1f7;
    cursor: pointer;
}
.view-all{
    font-size: 14px !important;
}
.padding-15{
    padding: 15px !important;
}
.view-all-icon{
    font-size: 15px;
    margin-left: 20px;
}
.sales-popover > div{
    width: 40%;
    max-width: 40%;
    z-index: 10000000;
    box-shadow: 0 0 10px #4d4f5b;
}
.sales-item{
    background: #fff;
}
.no-scroll{
    overflow: hidden;
}
.ongoing-sales-div{
    z-index: 10000000;
}
.popover-img{
    vertical-align: top;
}
.popover-username{
    margin-left: 10px;
}
h3.popover-header{
    padding: 20px;
    background: #fff;
}
.popover-border{
    padding:16px 20px;
}
div.popover-body{
    padding:20px 20px 0px 20px;
}
.popover-row .popover-border:first-child{
    border-right:1px solid #eaebf1;
}
.popover-breadcrumb{
    margin-bottom: 15px;
}
.community-popover-breadcrumb{
    border:none !important;
}
.popover-row{
    border-top:1px solid #eaebf1;
}
.popover-content{
    padding: 0px;
}
.popover-content-title{
    font-size: 14px !important;
    font-weight: 600;
    color: $text-color-black !important;
}
.popover-content-value{
    color: #9c9bb2;
    font-size: 14px !important;
    //margin-top: 5px;
}
.popover-title{
    font-size: 22px !important;
    font-weight: 600;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 15px;
    color: $text-color-black !important;
}
.popover-companyname{
    font-size: 14px !important;
}
.sales-popover:before{
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        display: block;
        z-index: 1000000;
        width: 100%;
}
.sales-popover > div:after {
    content: '';
    position: absolute;
    top:16px;
    bottom:0;
    width:41px;
    height: 100px;
    margin: auto;
    display: none;
}
.sales-popover > div.bs-popover-right:after{
    background: url(../../../../assets/images/content-images/popover-arrow.png) 0 0 no-repeat;
    left:-40px;
}
.sales-popover > div.bs-popover-left:after{
    background: url(../../../../assets/images/content-images/popover-arrow.png) 0 0 no-repeat;
    right:-40px;
    transform: rotate(180deg);
}
.breadcrumbs_container {
    span{
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
    }
}
.no-records{
    text-align: center;
    font-size: 16px;
    padding: 20px;
}
.stepper-time{
    margin-left: 2%;
}
.community-filter{
    padding: 10px 30px;
    text-align: center;
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 10px;
    cursor: pointer;
}
.pipelineStageLabel{
    font-size: 14px !important;
    color: $text-color-black;
    font-weight: 600;
}
.pipelineStageValue{
    font-size: 14px !important;
    color: #9c9bb2;
}
.pipelineStage_section{
    margin-bottom: 10px;
}

.leadLinearProgress > div{
    background-color: $lead-color !important;
}
.opportunityLinearProgress > div {
    background-color: $opportunity-color !important;
}
.proposalLinearProgress > div{
    background-color: $proposal-color !important;
}
.negotiationLinearProgress > div {
    background-color: $negotiation-color !important;
}
.closedLinearProgress > div{
    background-color: $closed-color !important;
}
.newLinearProgress > div {
    background-color: $new-color !important;
}

.item-img-display-inline-top{
    vertical-align: top;
    position: relative;
    top: 3px;    
}
.hand-shake-icon {
    width: 66px;
    height: 43px;
}

// .channel_separator {
//     margin: 20px 0;
//     background-color: rgb(234, 235, 238);
//     height: 1px;
//     width: 100%;
// }
// .channel_partners {

// }

/*** media query **/
@media(max-width:768px){
.content-values{ font-size: 36px !important; }    
.sales-content-text {font-size: 18px !important; margin-top: 0px;}
.item-title{ font-size: 14px !important;}
}
@media(max-width:753px){
.card-title.community-header{ display: block; text-align: center; height: 100%; margin: 16px 0 16px 0; border-bottom: 0px;}
.card-title.community-header .title{ display: block; width: 100%; margin: 0px;}
}
@media(max-width:767px){
    .item-title{
        width: 170px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
/*** media query **/
