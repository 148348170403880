.date_container {
    padding: 10px 10px 0 10px;

    .date {
        margin: 0;
        font-size: 14px;
        color: #232f44;
    }
}

.activities_container {
    width: 100%;
    border-radius: 5px;
    background-color: #ffffff;
    color: #1c2535;

    hr {
        margin: 0;
    }
}

.activities_container_header {
    width: 100%;
    height: 54px;
    background-color: #232f44;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.activities_container_heading {
    font-size: 16px;
    color: #ffffff;
    margin: 0;
}

.activities_container_actions {
    display: flex;
    justify-content: center;
}

.activities_container_body {
    overflow-y: auto;
    height: 410px;
    width: 100%;
}

.activities {
    width: 100%;
    padding: 10px;
}

.tasks {
    border-left: 5px solid #232f44;
    width: 100%;
    border-radius: 5px;
    background-color: #eeeff0;
    margin-bottom: 10px;

    .edit_delete {
        height: 36px !important;
    }

    .task {
        padding: 5px 0px 5px 20px;
        min-height: 36px;
        display: flex;
        align-items: center;
    }

    .tasks_with_lead {
        padding: 10px 0px 0px 0px;

        .lead_name {
            margin: 0 0 0px 20px;
        }
    }
}

.dots {
    position: relative;
    background-color: #b2b2b3;
    border-radius: 5px;
    font-size: 0;
    margin-right: 2px;
    padding: 2px;
}

.deleteicon {
    background: url('../../../../assets/delete_icon.png');
    background-repeat: no-repeat;
    height: 18px;
    width: 14px;
    margin-right: 10px;
}

.editicon {
    background: url('../../../../assets/edit_icon.png');
    background-repeat: no-repeat;
    height: 18px;
    width: 18px;
    margin-right: 10px;
}

.menuitem {
    display: flex;
    align-items: center;
}

.contact_detail {
    display: flex;
    width: 100%;
    margin: 3px 0 0px 20px;

    .contact {
        display: inline-block;
        min-width: 50%;
        padding-left: 20px;
        position: relative;
        margin-right: 5px;

        .contact_name {
            width: 130px;
        }
    }

    img {
        width: 14px;
        height: 14px;
        margin-right: 9px;
        position: absolute;
        left: 0;
        top: 1px;
    }

    h6 {
        // display: inline;
        // font-size: 12px;
        // color: #1c2535;
        display: block;
    font-size: 12px;
    color: #1c2535;
    width: 70px;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
     
    }
}