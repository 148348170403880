.oops_error {
    width: 100%;
    height: 100%;
    padding: 25px;
    text-align: center;
    overflow: hidden;

    .oops_error_img {
        max-width: 90%;
    }

    .oops_error_text {
        font-size: 18px !important;
        font-weight: 600;
        color: #232f44;
    }

    .oops_error_text2 {
        font-size: 16px !important;
        color: #232f44;
    }
}