@import '../../common/variables.scss';

.filterSection{
    padding: 0px 20px 20px 25px;
    display: inline-block;
    width: 100%;
}
.filter_section{
    width: 100%;
}
.width_15{
    width: 15% !important;
}
.width_17{
    width: 17% !important;
    height: 45px;
}
.width_25{
    width: 25% !important;
}
.width_30{
    width: 30% !important;
    height: 45px;
}
.width_22{
    width: 22% !important;
    height: 45px;
}
.width_9{
    width: 8% !important;
    height: 45px;
}
.filter_header_section{
    text-align: center;
    font-size: 16px !important;
    font-weight: 600;
    padding: 15px 0px 17px 0px;
    
    cursor: pointer;
    color: $text-color-black !important;
}
.filter{
    text-align: center;
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px !important;
}
.all_filter{
    padding: 10px;
    border: 2px solid $all-color;
    color: $all-color;
}
.new_filter{
    padding: 10px;
    border: 2px solid $new-color;
    color: $new-color;
}
.lead_filter{
    border: 2px solid $lead-color;
    padding: 10px;
    color: $lead-color;
}
.opportunity_filter{
    border: 2px solid $opportunity-color;
    padding: 10px;
    color: $opportunity-color;
}
.proposal_filter{
    padding: 10px;
    border: 2px solid $proposal-color;
    color: $proposal-color;
}
.negotiation_filter{
    padding: 10px;
    border: 2px solid $negotiation-color;
    color: $negotiation-color;
}
.closed_filter{
    padding: 10px;
    border: 2px solid $closed-color;
    color: $closed-color;
}
.parked_filter{
    padding: 10px;
    border: 2px solid $parked-color;
    color: $parked-color;
}
.selected_all_filter{
    background: $all-color;
    color: $white-color;
}
.selected_new_filter{
    background: $new-color;
    color: $white-color;
}
.selected_lead_filter{
    background: $lead-color;
    color: $white-color;
}
.selected_opportunity_filter{
    background: $opportunity-color;
    color: $white-color;
}
.selected_proposal_filter{
    background: $proposal-color;
    color: $white-color;
}
.selected_negotiation_filter{
    background: $negotiation-color;
    color: $white-color;
}
.selected_closed_filter{
    background: $closed-color;
    color: $white-color;
}
.selected_parked_filter{
    background: $parked-color;
    color: $white-color;
}
.arrow_up, .arrow_down{
    font-size: 15px;
    margin-left: 20px;
}



/****/
@media(max-width:1024px) {
.width_15, .width_22, .width_25{ width: auto !important;}
}

@media(max-width:767px) {
    .filter {
        width: auto !important;
    }
}
