.search-text {
	width: 100%;
	padding-left: 25px !important;
}
.search-text > div {
	color: rgba(255, 255, 255, 0.5);
}
.contact-item {
	border-bottom: 1px solid #d6d4d4;
	padding: 14px 10px 10px 10px;
	cursor: pointer;
	background: #f8f9ff;
	height: 71px;
}
.pipeline-grid .contact-item:last-child {
	border-bottom: none;
}
.contact-col-1 {
	max-width: 9%;
	flex: 0 0 9%;
	text-align: center;
}
.person-dialog {
	display: flex;
	margin-left: 15px;
}
.person-dialog-ongoing {
	flex-direction: column;
}

.header-image {
	position: relative;
	/* top: 7px; */
	/* top: 18px; */
	top: 0px;
}
.contact-popup-top-img {
	top: 9px;
}
.person-dialog-title {
	margin-left: 10px;
	margin-top: -7px;
}
.contact-col-2 {
	max-width: 91%;
	flex: 0 0 91%;
	padding-right: 6px;
}
.pipeline-grid {
	border-radius: 4px;
	border: 1px solid #eaebf1;
}
.contact-name {
	font-weight: 600;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 14px !important;
}
.contact-name span {
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: 90%;
}

.company-name-my-pipeline {
	width: 80%;
	color: #a7abb4;
	font-size: 12px !important;
	font-weight: normal;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.for_email {
	width: 90%;
	float: right;
}
.contact-item-inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.contact {
	margin-left: 10px;
	width: 90%;
}
.right-phone-text,
.right-email-text {
	color: #a7abb4;
	font-size: 12px !important;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.right-arrow-icon {
	margin-left: 30%;
	color: #8a8686;
	font-size: 20px !important;
	margin-bottom: 10%;
}
.right-section {
	width: 50%;
	text-align: right;
}
.left-section {
	margin-left: 10px;
	width: 100%;
}
.contact-search {
	padding: 10px;
}
.contact-search > div {
	width: 100%;
}
.contact-initial {
	font-size: 16px !important;
	font-weight: 700;
	color: #c4c4d6;
}
.scroll-list {
	height: 435px;
	overflow-y: auto;
	padding: 15px;
}
.padding-0 {
	padding: 0px !important;
}
.modal-zindex {
	z-index: 1000 !important;
}
.dialog-company-name {
	font-size: 14px !important;
	color: #232f44;
	font-weight: 500;
	/* padding-bottom: 16px */
	padding-bottom: 5px;
	font-weight: 600;
}

.dialog-title-name {
	font-size: 14px !important;
	color: #232f44;
	font-weight: 500;
	/* padding-bottom: 16px */
	padding-bottom: 5px;
	font-style: italic;
}

.dialog-title {
	word-break: break-word;
	font-size: 22px !important;
	font-weight: 600;
}
.dialog-content-title {
	font-size: 14px !important;
	color: #232f44;
	font-weight: 600;
	margin-left: 5px;
}
.contact-first {
	display: flex;
	align-items: center;
	width: 45%;
	overflow: hidden;
}
.dialog-content-value {
	font-size: 14px !important;
	color: #9c9bb2;
	margin-left: 5px;
}
.notes-value {
	font-size: 14px !important;
	color: #9c9bb2;
}
.dialog-content-border {
	border-bottom: 1px solid #d6d4d4;
	padding: 20px;
}
.dialog-bottom-none {
	border-bottom: none;
}
.popover-breadcrumb {
	margin: 0 -16px;
	padding: 0 15px 15px;
	border-bottom: 1px solid #eaebf1;
	color: #232f44;
	font-weight: 500;
}
.pipeline-popover-breadcrumb {
	padding: 20px 20px 20px !important;
}
.dialog-btn {
	/* padding:20px 10px 10px 20px; */
	padding: 25px 10px 25px 20px;
	justify-content: end;
}
.update-btn {
	background-color: #232f44;
	color: white;
	border: none;
	width: 160px;
	height: 46px;
	text-align: center;
	border-radius: 5px;
	margin-right: 5px;
}
.event-date {
	padding: 10px;
	font-size: 14px !important;
	font-weight: 600;
}
.meeting-event {
	padding: 15px;
	background-color: #fbe9ec;
	border-left: 5px solid #e1667f;
	border-radius: 5px;
}
.email-event {
	padding: 15px;
	background-color: #e5f0fe;
	border-left: 5px solid #4c97f7;
	border-radius: 5px;
}
.task-event {
	padding: 15px;
	background-color: #eeeff0;
	border-left: 5px solid #232f44;
	border-radius: 5px;
}
.event-time {
	font-size: 16px !important;
	font-weight: 700;
	padding-top: 1%;
}
.event-type {
	font-size: 14px !important;
	font-weight: 700;
}
.event-img {
	width: 15%;
	margin-right: 2%;
}
.company-img {
	width: 10%;
	margin-right: 2%;
}
.pipeline-sales-item {
	padding: 15px 0px;
	cursor: pointer;
	border-bottom: 1px solid #eaebf1;
}
.contact-item:hover {
	background: #f0f9f1;
}
.contact-list {
	margin-bottom: 20px;
	overflow-x: hidden;
}
.ongoing-sales-card {
	max-height: 500px;
}
.ongoing-sales-body {
	overflow-y: auto;
	height: 450px;
	padding: 0px 15px 0px 15px !important;
	overflow-x: hidden;
}
.error-removed-padding {
	padding: 0px !important;
}
.contact-details-row {
	padding: 20px;
	border-bottom: solid 1px #eaebf1;
}
.contact-details-row-box {
	border-right: solid 1px #eaebf1;
}
.contact-details-row-box-padding {
	display: flex;
}
.contact-list-display-image {
	width: 28px;
	height: 28px;
}
.pipeline-popup-row {
	border-bottom: 1px solid #eaebf1;
}
.pipeline-popup-grid {
	display: flex;
	align-items: center;
	padding: 20px 30px !important;
	border-right: 1px solid #eaebf1;
}
.pipeline-popup-content {
	margin-top: -16px;
}
.pipeline-stepper-span {
	font-size: 12px !important;
	padding-left: 12px !important;
}
/* .pipeline-stepper-span > time{
    margin-left: 5%;
} */
.handoverIcon {
	position: absolute;
	display: inline-block;
	/* right: 10%; */
	right: 51px;
}
.handoverIconOnPopup {
	display: inline-block;
	right: 40px;
}
.ringIcon {
	margin-right: 5%;
}
.filterIcon {
	position: absolute;
	right: 15px;
	top: 15px;
	cursor: pointer;
}
.view-more-container {
	width: 100% !important;
	padding: 3%;
	text-align: center;
}
.view-more-btn {
	background: #17d98b;
	border: 2px solid #17d98b;
	padding: 2%;
}
.personDetailPopup > div > div {
	width: 1000px;
}
.companyDetailPopup > div > div {
	width: 1000px;
}
.leadFromPopup > div > div {
	width: 1100px;
}
.status-section {
	margin-top: 5%;
	margin-right: -8%;
}
.pipeline-item-icon {
	margin-left: 40%;
	color: #8a8686;
	font-size: 20px !important;
	margin-bottom: 10%;
}

@media (max-width: 767px) {
	.scroll-list {
		overflow-x: hidden;
	}
	.right-section {
		overflow: hidden;
		padding-left: 10px;
		white-space: nowrap;
	}
	.card-rounded.ongoing-sales-card {
		margin-bottom: 30px !important;
	}

	.pipe-linemain-div > div > div {
		margin: 48px 20px !important;
	}
	.width > div > div {
		margin: 48px 20px !important;
	}
	.leadFromPopup > div > div {
		margin: 48px 20px !important;
	}
}

.breadcrumbs_container_orgs,
.orgs_user_dropdowns {
	display: flex;
	justify-content: space-between;
}

.orgs_user_dropdowns {
	min-width: 510px;
	max-width: 510px;
	max-height: 100px;
	margin-bottom: 10px;
}

@media (max-width: 768px) {
	.pipe-linemain-div > div > div {
		margin: 48px 20px !important;
	}
	.width > div > div {
		margin: 48px 20px !important;
	}
	.leadFromPopup > div > div {
		margin: 48px 20px !important;
	}

	.breadcrumbs_container_orgs {
		display: block;
	}
}

@media (max-width: 600px) {
	.orgs_user_dropdowns {
		min-width: 100%;
		max-width: 100%;
		display: block;
		margin-top: 10px;
	}
}

@media (max-width: 480px) {
.companyDetailPopup > div > div { margin: 48px 20px !important;}
.person-dialog {
    display: block;
    margin-left: 15px;
}
.person-dialog-title {
    margin-left: 0;
    margin-top: -7px;
    display: block;
    width: 100%;
    margin-top: 10px !important;
}
.dialog-content-title{ display: block;}

}
