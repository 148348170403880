.sales_news {
    width: 100%;
    border-radius: 5px;
    background-color: #ffffff;
}
.sales_news_header {
    padding: 10px 20px;
    display: flex;
}

.info_container {
    display: inline-block;
    width: 100%;
    margin-left: 10px;
}

.org_time {
    display: flex;
    justify-content: space-between;
}

.imgSizeWrapper{
    width: auto !important;
    margin-left: auto !important;
    margin-right: auto !important;
    display: block !important;
}

.sales_names_icon_container {
    display: inline-block;
    img {
        height: 36px;
        width: 36px;
    }
}

.sales_news_image_container {
    background-color:#f9f9f9;
    width: 100%;
    height: 290px;
    img {
        width: 100%;
        height: 100%;
    }
}

.sales_news_content {
    white-space: pre-wrap;
    padding: 20px;
    width: 100%;
    color: #232f44;
    .heading {
        font-size: 16px;
        font-weight: bold;
    }
    .content {
        font-size: 14px;
        word-break: break-word;
    }
}
.container-body.btm-space{
    padding-bottom:20px;
}
