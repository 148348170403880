.title_Administration {
  margin: 0px;
  font-size: 1.25em;
  font-weight: bold;
  color: #232f44;
  text-align: left;
}

.org_profile_logo {
  margin-top: 2.145em;
  width: 12.5em;
}

.card_view {
  margin-top: 0.625em;
}

.bottom_update_button {
  text-align: right;
  margin-top: 4.375em;
  margin-bottom: 4.375em;
}

.title_text {
  margin-top: 1.25em;
  font-weight: 650;
}

.email_text {
  margin-top: 1.25em;
}

.toggle_container {
  padding-top: 1.375em;
  text-align: right;
}

.title_right_text {
  margin-top: 1.875em;
  font-weight: 650;
  font-size: 1rem !important;
}

.description_text {
  margin-top: 56px;
  font-weight: 650;
  font-size: 1rem !important;
}

.Select {
  width: 27.75em !important;
  margin-right: 0.3125em;
  z-index: 0 !important;
}

.Select.is-open {
z-index: 1 !important;
}

.Select-menu {
  position: fixed !important;
  width: 27.5em !important;
  margin: 0.0625em;
}

.url_container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 12px;
}

.icon_pic {
  height: auto;
  float: right;
  width: 20px;
  cursor: pointer;
}

.p_text {
  color: #232F44;
  font-weight: 400;
  font-family: 'Open Sans'!important;
}

.generateUrlBtn {
  font-size: xx-small !important;
  padding: inherit !important;
}

.disabled {
  color: #8a919c;
  font-weight: 700 !important;
  
}

.cursor_pointer {
  cursor: pointer;
  font-weight: 650;

}