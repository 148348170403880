.no_records {
    width: 100%;
    height: 100%;
    padding: 30px;
    text-align: center;

    .no_records_img {
        // width: 100%;
        // max-width: 76%;
        max-width: 90%;
    }

    .no_records_text {
        font-size: 18px !important;
        font-weight: 600;
        color: #232f44;
    }
}