.title_Administration {
    margin: 0px;
    font-size: 1.25em;
    font-weight: bold;
    color: #232f44;
    text-align: left;
}

.title_text {
    margin-top: 1.25em;
    font-weight: 650;
}
.search_box {
    text-align: right;
}

#pendingusers td{
    padding: 10px !important;
}

.draggableStyle{
    border-color: rgb(120, 120, 123) !important;
    background-color: rgb(244, 244, 244) !important;
}