.login_page {
    width: 100%;
    min-height: 100vh;
    background-image: url(../../../../assets/bg/forgot_pwd.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: 'Open Sans', sans-serif;
    display: flex;
    justify-content: center;
}

.login {
    width: 400px;
    margin: auto;
    box-sizing: border-box;
    text-align: center;
    vertical-align: middle;
    color: #ffffff;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000029;
    opacity: 1;
    height: auto;
  
  
    .logo_container {
      padding: 30px;
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 17px;
  
      .logo {
        width: 10rem;
        height: auto;
      }
}

.welcome {
    width: 100%;
    margin-bottom: 56px;

    .heading {
      font-size: 30px;
      font-weight: 300;
      margin: 0 0 10px 0;
      display: inline-block;
      color: #232F44;
    }

    .heading_leadx {
      font-size: 36px;
      font-weight: 300;
      margin: 0 0 10px 0;
      font-weight: bold;
      display: inline-block;
      margin-left: 10px;
      color: #232F44;
    }

    h5 {
      color: #232F44; 
      font-size: 14px;
      text-align: center;
    }
  }

  .login_form {
    width: 100%;
    margin-bottom: 40px;

    .input_container {
      margin-bottom: 30px;
      width: 100%;
      max-width: 100%;
    }

    .input {
      width: 80%;
      height: 45px;
      border: 1px solid #BDBEC0;
      border-radius: 8px;
      opacity: 1;
      padding: 12px;
      font-style: italic;
      color: #48484F !important;
    }
    .input::after {
      border-bottom: none !important;
    }

    input:-webkit-autofill {
      background-color: #ffffff !important;
      font-style: italic;
    }
    .input::before {
      border-bottom: none !important;
    }

    ::placeholder {
      color: #a1abbb;
      opacity: 1;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #a1abbb;
    }

    .login_button_container {
      display: flex;
      justify-content: center;
      height: 50px;

      .button {
        height: 100%;
        background-color: #808080;
        color: #ffffff;
        padding: 13px 34px;
        cursor: pointer;
        border: none;
        font-size: 16px;

        span {
          text-transform: none;
          font-size: 16px !important;
          line-height: normal;
        }
      }

      .button:disabled {
        background-color: grey;
        cursor: not-allowed;
      }
    }
  }
  .forgot_password {
    display: flex;
    margin-bottom: 5px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    span{
      h5 {
        font-size: 16px;
        
        a {
          color: #ffffff !important;
          text-decoration-line: underline;
        }
  
        a:hover,
        a:active {
          color: #17d98b !important;
        }
      }
    }

    h5 {
      font-size: 16px;
      color: #232F44;
    
      a {
        text-decoration-line: underline;
        color: #17d98b !important;
        font-weight: bold;
      }

      a:hover,
      a:active {
        color: #17d98b !important;
      }
    }
  }
}

.errors {
    color: #cc0000;
    display: block;
}

@media(max-width:767px) {
    .login {
        width: auto;
        max-width: 400px;
    }
}

@media only screen and (max-width: 400px) {
    .login{
        max-width: 94%;
        margin-left: auto;
        margin-right: auto;
    }   
    .login {
        .welcome{
            .heading5{
                width: 100%;
            }
        }


        .login_form{
            width: 100%;
        }


    }





}