.main_wrapper {
    padding: 20px 30px;
}

.status_mapping_container {
    width: 100%;
    border-radius: 5px;
    background-color: #ffffff;
    color: #1c2535;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    hr {
        margin: 0;
    }
}

.status_mapping_container_header {
    width: 100%;
    height: 54px;
    background-color: #232f44;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.status_mapping_container_heading {
    font-size: 16px;
    color: #ffffff;
    margin: 0;
}

.status_mapping_container_actions {
    display: flex;
    justify-content: center;
    position: relative;
}

.calendar_container {
    display: flex;
    justify-content: flex-end;
    .buttons {
        width: 66px;
        background-color: #ffffff;
        border: solid 2px #eaebf1;
        color: #9c9bb2;
        border-radius: 3px;
        margin-left: 10px;
        height: 46px;
    }
    .selected {
        border: solid 2px #232f44;
        color: #232f44;
    }
    .date_picker_icon {
        background: url(../../../../../src/assets/images/content-images/calender.png) 0 0 no-repeat;
        width: 22px;
        height: 21px;
        display: inline-block;
        // position: absolute;
        // right: 35px;
        // top: 38px;
        pointer-events: none;
    }
}

.centerAlign {
    align-items: center !important;
}

@media only screen and (max-width: 480px) {
    .calendar_container {
        margin-top: 20px;
    }
}

.jss201 {
    height: 1px;
    margin: 0;
    border: none;
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 0.12);
}

.profileContainer_label__ykzKB {
    font-size: 14px!important;
    text-align: left;
    color: #232f44;
    margin-bottom: 0!important;
}

.profileContainer_inputStyle__1Dduu {
    height: 46px;
    border: 1px solid silver;
    margin-bottom: 12px!important;
    margin-top: 5px!important;
    width: 100%;
    padding: 5px;
    border-radius: 3px;
}

.profileContainer_update_btn__MXNDH {
    background-color: #232f44;
    color: #fff;
    font-size: 14px!important;
    border: none;
    border-radius: 3px;
    margin-top: 15px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 26px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #19d98c !important;
}

input:focus+.slider {
    box-shadow: 0 0 1px #19d98c !important;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider_round {
    border-radius: 34px;
}

.slider_round:before {
    border-radius: 50%;
}

.status_mapping {
    background-color: #ffffff;
    border-radius: 5px;
    outline: none;
    width: 1069px;
}

.status_mapping_dialog_title {
    font-size: 22px !important;
    font-weight: 600;
    text-align: center;
    // margin: 5px 0 4px 0px;
    margin: 5px 0px;
    width: 100%;
}

.status_mapping_dialog_content {
    overflow-x: hidden !important;
    
    // width: 1069px;
    width: 100%;
}

.required_field {
    color: red;
}

.form_group {
    margin-bottom: 15px;

    input:focus {
        outline: none;
    }
}
.form_group input::placeholder {
    color: #9c9bb2 !important;
    font-size: 13px !important;
  }

.form_title {
    font-size: 14px !important;
    margin-bottom: 5px !important;
    color: #232f44 !important;
}

.inputStyle {
    height: 46px;
    border: solid 1px #c0c0c0;
    margin-bottom: 8px;
    margin-top: 5px !important;
    width: 100%;
    padding: 5px;
    border-radius: 3px;
 
}

.inputStyle:focus,
.selectCompany:focus,
.textArea:focus {
    outline: none;
}


.selectCompany {
    cursor: pointer;
    height: 46px;
    margin-top: 5px !important;
    border: solid 1px #c0c0c0;
    width: 100%;
    background-color: white;
    padding: 5px;
    border-radius: 3px;
    -webkit-appearance: none;
    background: url(../../../../assets/images/content-images/select-arrow.png) 99% center no-repeat;
}

.removeBorder {
    border-top: 0 !important;
    padding: 10px !important;
}

.saveButton {
    width: 160px;
    height: 46px;
    border-radius: 3px;
    color: white !important;
    background-color: #0b0e22 !important;
    margin: 9px 0 0 0 !important;
    text-transform: capitalize !important;
    cursor: pointer;
}

.datepicker {
    position: relative;
}

.textArea {
    resize: none;
    height: 46px;
    width: 100% !important;
    margin-right: 0px !important;
    border: solid 1px #c0c0c0;
    border-radius: 3px;
    padding: 5px;
}

.optional {
    color: #9c9bb2;
    font-size: 12px;
    font-weight: normal;
    font-style: italic;
}

.helperText {
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #9c9bb2;
    font-size: 10px !important;
    margin: 7px 0px 0px 0px !important;
    line-height: 14px !important;
}

.dialogTitle {
    width: 100%;
    margin: 0;
    position: relative;
}

.submit_loader {
    position: relative;
    width: 85px;
    height: 50px;
}

.info  {
    width: 20px;
    height: 20px;
    /*background: transparent url(../../../../../src/assets/images/content-images/new_star.png) 0 0 no-repeat;;*/
    opacity: 1;
    border:0px;
    margin-bottom: 4px;
    margin-right: 5px;
}
/* 
.status_mapping_container {
    width: 100%;
    border-radius: 5px;
    background-color: #ffffff;
    color: #1c2535;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    hr {
        margin: 0;
    }
} */
/* 
.status_mapping {
    width: 100%;
    height: 54px;
    background-color: #232f44;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.status_mapping_container_heading {
    font-size: 16px;
    color: #ffffff;
    margin: 0;
}

.status_mapping_container_actions {
    display: flex;
    justify-content: center;
    position: relative;
} */

@media only screen and (max-width: 1024px) {
.status_mapping{ width: 100%; overflow-x: hidden;}    
   
}
@media only screen and (max-width: 767px) {
.status_mapping_dialog_title { text-align: left; margin-left: 20px;}
.status_mapping_dialog_content { padding: 15px !important;  position: relative;}

}
@media only screen and (max-width: 400px) {
.status_mapping_dialog_title { font-size: 16px !important; position: relative; top: 0px;}
}