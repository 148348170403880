.sweet_loading {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: block;
    z-index: 1000000;
    width: 100%;
}
.sweet_loading > div > div{
    opacity: unset;
    margin-top: 35%;
    z-index: 1000000;
}
.sweet_loading > div{
    margin-left: 45%;
    opacity: unset;
    z-index: 1000000;
}