.title_Administration {
  margin: 0px;
  font-size: 1.25em;
  font-weight: bold;
  color: #232f44;
  text-align: left;
}
// Card
.card_height {
  height: auto
}

.card_padding {
  padding: 1.25em
}

// Card Image
.image_container {
  display: flex;
  justify-content: center;
}
.display_image {
  width: 100%;
  max-width: 9.375em;
  height: auto;
}

.border-right > [class*='col-']:first-child:before {
  display: none;
}

// Card Title
.option_title {
  font-size: 1.5625em !important; 
  font-weight: 650; 
  color: #62616E;
}

.bottomPadding {
  padding-bottom: 30px;
}

// @media (min-width: 992px)
@media(max-width:768px)
{
  .bottomPadding {
    padding-bottom: 30px;
  }
  .card_height {
    height: 24.5em
  }
}
