tbody {
  border-bottom: 2px solid #dee2e6;
}
.th {
  border-top: none !important;
  font-size: 18px !important;
  color: #62616E !important;
}

.tableDiv {
  padding: 20px; 
}

.menu-outer-top .Select-menu-outer {
  bottom: 35px!important;
  border-bottom-right-radius: 0px!important;
  border-bottom-left-radius: 0px!important;
  border-top-right-radius: 4px!important;
  border-top-left-radius: 4px!important;
}

.closeIcon {
  text-align: right;
  margin-top: -40px;
}

.lead_dialog_title {
    font-size: 22px !important;
    font-weight: 600;
    text-align: center;
    // margin:5px 0 4px 0px;
    margin: 5px 0px;
  }
  .lead_dialog_content {
    padding: 25px 30px 20px 30px !important;
    overflow: unset !important;
  }
  .removeBorder {
    border-top: 0 !important;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-right: 1em;
    padding: 10px 0px 10px 10px !important;
  }
  .saveButton {
    width: 160px;
    height: 46px;
    object-fit: contain;
    border-radius: 3px;
    color: white;
    // background-color: #0b0e22 !important;
    margin-right: 0px !important;
    margin-top: 15px !important;
    text-transform: capitalize !important;
  }
  .saveButton:disabled {
    color: #8a919c !important;
    background-color: #f5f5f5 !important;
    border: #eaebf1 !important;
  }
  
  .popup_title {
    text-align: center;
    font-size: 28px !important;
    font-weight: bold;
    color: #232F44;
    padding-top: 7px;
  }