
.main_wrapper{
    padding: 20px 30px 20px 30px;
}

.sales_feed {
    padding: 0px !important;
    width: 100%;
    border-radius: 5px;
    background-color: #ffffff;
}

.sales_feed_header {
    padding: 8px;
    height: 52px;
    position: relative;
    display: flex;
    background: #233044; /* Old browsers */
}

.sales_feed_header.notification {
    background: -moz-linear-gradient(left,  #233044 32%, #2b4b48 46%, #2f564a 54%, #39774f 73%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  #233044 32%,#2b4b48 46%,#2f564a 54%,#39774f 73%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  #233044 32%,#2b4b48 46%,#2f564a 54%,#39774f 73%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#233044', endColorstr='#39774f',GradientType=1 ); /* IE6-9 */
}

.sales_feed_header.notification:before{
    content: '';
    position: absolute;
    right:0;
    top:-19px;
    width:93px;
    height: 85px;
    background: url(../../../../assets/images/feeds-page/notification-icon.png) 0 0 no-repeat
}

.sales_feed_icon_container {
    margin-right: 8px;
    img {
        height: 36px;
        width: 36px;
        display: inline-block;
    }
}

.info {
    display: flex;
    justify-content: space-between;
}

.title_timing {
    margin-top: -4px;
    vertical-align: middle;
    
    
}

.heading {
    font-size: 14px;
    color: #ffffff;
    margin: 0px;
    .bold_title {
        font-weight: bold
    }
}

.timing {
    font-size: 12px !important;;
    color: #9c9bb2;
    margin: 0px;
}

.sales_feed_content {
    padding: 20px 30px;
    color: #232f44;
    height: auto;
}

.salesFeedDiscription{
font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left !important;
   color: #232f44;
    margin: 0px !important;
    text-align: justify;
}

@media(max-width:424px) {
.sales_feed_header { height: 110px;}
}